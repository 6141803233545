import { api } from "..";
import { useQuery, useQueryClient } from "@tanstack/react-query";

/**
 * Client hook to prepare a Sale reference ID for the Patient/Responsible Party that is getting ready to pay.
 * @returns React Query properties.
 */
export function usePrepareSale(patientId: number) {
    const queryClient = useQueryClient();

    const { isPending, data, isSuccess, isFetching, isFetchedAfterMount } = useQuery({
        queryKey: ["prepareSale", patientId],
        queryFn: (context) => api.sales.prepareSale(patientId, context.signal),
        throwOnError: true,
        retry: 3,
        // staleTime sets how long to *only* refer to cached data and not make a network call. We don't want new
        // reference IDs being generated unnecessarily, so we keep using the cached data for as long as we can. This
        // should effectively disable the different refetchOn{whatever} mechanisms.
        staleTime: Infinity,
        // cacheTime is like garbage collection. As soon as nothing is using this query any more, immediately delete
        // data from the cache. This means if we are in the Pay Now dialog, close it, and then immediately reopen it for
        // the same patient, we will prepareSale again, which is what we want. It doesn't matter that staleTime is
        // infinite, when the cached data is gone we can't keep using it.
        gcTime: 0,
    });

    const invalidatePrepareSale = () => {
        void queryClient.invalidateQueries({
            queryKey: ["prepareSale", patientId],
        });
    };

    return {
        saleLoading: isPending || isFetching || !isFetchedAfterMount,
        data,
        isSuccess,
        invalidatePrepareSale,
    } as const;
}
