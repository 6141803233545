import { captureException, withScope } from "@sentry/react";

export function componentDidCatch(error: Error, { componentStack }: React.ErrorInfo) {
    // adapted from https://github.com/getsentry/sentry-javascript/blob/d1f0b608682c68733861fe54c0f060da50dadf03/packages/react/src/errorboundary.tsx#L90
    withScope((scope) => {
        const errorBoundaryError = new Error(error.message || "no message");
        errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
        errorBoundaryError.stack = componentStack ?? undefined;
        error.cause = errorBoundaryError; // takes advantage of `LinkedErrors` integration that's enabled by default
        scope.setContext("react", { componentStack });
        try {
            scope.setContext("caught", { name: error.name, stack: error.stack });
        } catch (errorInspectingError) {
            console.warn("error inspecting error", errorInspectingError);
        }
        captureException(error, scope);
    });
}
