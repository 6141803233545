import { stripeConfirm } from "./stripeConfirm";
import type { SaleIntentMutator } from "api/clients/useSaleIntent";
import type { StrictlyBasisPoints } from "utils/currency";
import type { Stripe, StripeElements } from "@stripe/stripe-js";
import type { StripeConfirmedEvent, StripeEvent, StripeState } from "./stripeFlow.props";

type SubmittingState = Extract<StripeState, { type: "submitting" }>;

/**
 * Submit the payment via Stripe.
 * @param stripe Stripe API instance provided by useStripe() hook.
 * @param elements Stripe Elements provided by useElements() hook.
 * @param mutateAsync Callback to submit a Payment Intent from useSaleIntent() hook.
 * @param state State indicating we are submitting payment.
 * @param dispatch Callback to submit a state-changing action.
 * @param payNow Flag indicating if we are in the Pay Now dialog (true) or Patient Portal (false).
 * @param balance Total balance we are making a payment towards.
 * @param token Optional patient access token for Patient Portal.
 * @returns Promise representing asynchronous processing.
 */
export async function submitCore(
    stripe: Stripe,
    elements: StripeElements,
    mutateAsync: SaleIntentMutator,
    state: SubmittingState,
    dispatch: React.Dispatch<StripeEvent>,
    payNow: boolean,
    balance: StrictlyBasisPoints,
    token?: string,
) {
    const { valid, elementsForConfirm } = await validateInputs(elements, state, dispatch);

    if (!valid) {
        // Bail out if inputs are not valid
        return;
    }

    // Create a new payment intent, or update existing
    // importantly, updates payment amount if user changes it after an initial decline
    const { intentId, clientSecret, returnUrl } = await mutateAsync(state);
    const eventProps = { intentId, clientSecret, payNow } as const;

    if (state.terminalId) {
        // card present case. not really confirmed just yet, but let's call it that...
        dispatch({ ...eventProps, type: "confirmed" });
        return;
    }

    // card not present cases
    await confirmCore(stripe, elementsForConfirm, dispatch, eventProps, prepareUrl(returnUrl, balance, payNow, token));
}

/** Validate the data entry form. */
async function validateInputs(elements: StripeElements, state: SubmittingState, dispatch: React.Dispatch<StripeEvent>) {
    const somethingOnFileSelected = !!state.cardOnFileId || !!state.terminalId;

    // Only perform Stripe form validation if we need to actually enter card data
    if (!somethingOnFileSelected) {
        // Trigger form validation and wallet collection
        const { error } = await elements.submit();

        if (error) {
            dispatch({ type: "error", error });
            return { valid: false, elementsForConfirm: undefined } as const;
        }
    }

    return { valid: true, elementsForConfirm: somethingOnFileSelected ? undefined : elements } as const;
}

type EventProps = Pick<StripeConfirmedEvent, "intentId" | "clientSecret" | "payNow">;

async function confirmCore(
    stripe: Stripe,
    elements: StripeElements | undefined, // Elements not passed here if using card on file
    dispatch: React.Dispatch<StripeEvent>,
    eventProps: EventProps,
    returnUrl: string, // return URL that's already been prepared
) {
    const { clientSecret, payNow } = eventProps;

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const { error } = await stripeConfirm(stripe, {
        elements, //  Elements must not be used here if using card on file
        clientSecret, // this by itself identifies the payment intent (and authorizes some interaction with it)
        confirmParams: {
            return_url: returnUrl,
        },
        // billing admin pay-now: redirect if_required (what we really mean is never)
        // patient portal: redirect always
        redirect: payNow ? "if_required" : "always",
    });

    if (error) {
        dispatch({ ...eventProps, type: "error", error });
    } else {
        dispatch({ ...eventProps, type: "confirmed" });
    }
}

function prepareUrl(rawUrl: string, balance: StrictlyBasisPoints, payNow: boolean, token?: string) {
    const url = new URL(rawUrl);

    // keep balance as basis points
    url.searchParams.append("balance", balance.toString());

    // if patient portal and token is present, append it
    if (!payNow && token) {
        url.searchParams.append("token", token);
    }

    return url.toString();
}
