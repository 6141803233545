import "./paymentPage.scss";
import { Card, Descriptions, Flex, Skeleton, Space, Typography, theme } from "antd";
import { PhoneTwoTone, PushpinTwoTone, ShopOutlined, SolutionOutlined } from "@ant-design/icons";
import { stringFromBasisPoints } from "@vyne-shared/utils/currency";
import { useStripeInitialContext } from "@vyne-shared/components/Payment/ProcessingPaymentFlow/Stripe/useStripeInitialContext";
import type { PatientPrepareSaleResult } from "@vyne-shared/api/responses";
import type { ProviderRolodexResponse } from "api/responses";

const { useToken } = theme;
const { Title } = Typography;
interface SummaryDataProps {
    rolodex?: ProviderRolodexResponse;
    sale?: PatientPrepareSaleResult;
}

export const SummaryData = ({ rolodex, sale }: SummaryDataProps) => {
    const { token } = useToken();
    const balance = useTotalBalance(sale);

    return (
        <Space direction="vertical" size="middle">
            <Card title={OfficeTitle} size="small">
                <Skeleton loading={!rolodex}>
                    <Flex align="start" justify="flex-start" gap="small" className="contact-row">
                        {/* Header Here Keeps It Aligned With Address Without Complex CSS */}
                        <Title level={2}>
                            <PushpinTwoTone twoToneColor={token.colorPrimary} />
                        </Title>
                        <address>
                            <Title level={2}>{rolodex?.name}</Title>
                            {/* eslint-disable-next-line @eslint-react/no-array-index-key -- okay for this particular data */}
                            {rolodex?.address.map((address, i) => <span key={`address-${i}`}>{address}</span>)}
                        </address>
                    </Flex>
                    {rolodex?.phone ? (
                        <Flex align="start" justify="flex-start" gap="small" className="contact-row">
                            <PhoneTwoTone twoToneColor={token.colorPrimary} />
                            <span>{rolodex.phone}</span>
                        </Flex>
                    ) : null}
                </Skeleton>
            </Card>
            <Card title={StatementTitle} size="small">
                <Skeleton loading={!sale}>
                    <Descriptions column={2}>
                        <Descriptions.Item label="Date">{sale?.statementDate}</Descriptions.Item>
                        <Descriptions.Item label="Balance">
                            <b>{stringFromBasisPoints(balance)}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Skeleton>
            </Card>
        </Space>
    );
};

const OfficeTitle = (
    <span>
        <ShopOutlined /> Office Details
    </span>
);

const StatementTitle = (
    <span>
        <SolutionOutlined /> Statement Details
    </span>
);

function useTotalBalance(sale: PatientPrepareSaleResult | undefined) {
    const { intentId, balance } = useStripeInitialContext();

    if (intentId && balance) {
        // see useTotalBalance in ThanksPatientPortalBody.tsx
        return balance;
    }
    return sale?.defaultBalance;
}
