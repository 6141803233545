import * as ignoreCase from "./ignoreCase";

/** Commonly used environment names. Copied from ASP.NET Core to provide a shared baseline. */
export enum EnvironmentName {
    Development = "development",
    Production = "production",
}

/** Provides information about the hosting environment an application is running in. */
export class Environment {
    constructor(environmentName: string) {
        this.environmentName = environmentName;
    }

    /** The name of the environment. */
    readonly environmentName: string;

    /**
     * Checks if the current host environment name matches the supplied name (case-insensitively).
     * @param name The name to compare against.
     * @returns True if the name matches, false otherwise.
     */
    is(name: string) {
        return ignoreCase.equals(this.environmentName, name);
    }

    /**
     * Checks if the current host environment name is {@link EnvironmentName.Development}.
     * @returns True if development, false otherwise.
     */
    isDevelopment() {
        return this.is(EnvironmentName.Development);
    }

    /**
     * Checks if the current host environment name starts with any of the supplied prefixes.
     * @param prefixes An array of prefixes to test.
     * @returns True if it starts with a supplied prefix, false otherwise.
     */
    hasAnyPrefix(prefixes: string[]) {
        return prefixes.some((prefix) => this.environmentName.toLowerCase().startsWith(prefix));
    }
}
