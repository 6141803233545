import { PayGPI } from "./GPI";
import { PayStripe } from "./Stripe";
import { usePayment } from "../usePaymentContext";

/**
 * Shared Payment component that allows a user to make a payment.
 *
 * Used in both Patient Portal and Pay Now. Supports both GPI and Stripe as payment processors.
 */
export const ProcessingPaymentFlow = () => {
    const { settings } = usePayment();

    return settings.paymentProcessor === "Stripe" ? <PayStripe /> : <PayGPI />;
};
