import {
    type StrictlyDollars,
    basisPointsFromDollars,
    stringFromDollars,
    unformattedStringFromBasisPoints,
} from "utils/currency";
import { useReducer } from "react";
import type { PaymentProcessor } from "api/responses";

interface AmountStateCore {
    /**
     * The full balance owed. Same as prepared sale defaultBalance, but drives internal amount state behavior.
     * For patients and non-integrated billing admins, this is the amount under collection in Payments.
     * For integrated billing admins, this will be the estimated patient responsibility from the PMS.
     */
    fullBalance: StrictlyDollars;

    /** True when user is a billing admin, in which case we will not cap the maximum amount (though we'll still warn). */
    isPayNow: boolean;

    /** True when PMS-integrated, in which case we'll tweak the wording of the warning shown when a high balance is entered. */
    isIntegrated: boolean;

    /** Is partial payment allowed? */
    isEditable: boolean;

    /** Which payment processor is handling the payment? */
    paymentProcessor: PaymentProcessor;

    /**
     * Holds the parsed and validated form of {@link stringAmount}. When paying in full, equal to {@link fullBalance}.
     */
    validatedAmount: StrictlyDollars | null;

    /**
     * Holds the string form of the desired payment amount, as entered as shown in AmountInput text box.
     * This is the state backing the controlled input component.
     */
    stringAmount: string;

    /**
     * Validation error, if there is one. If this is non-null alongside a non-null {@link validatedAmount}, the error
     * message is a warning that won't block submission.
     */
    error: string | null;
}

/** State pertaining to amount being paid */
export type AmountState = Readonly<AmountStateCore>;

/** Patient has edited the value in the AmountInput text box */
export interface AmountInputAction {
    type: "amountinput";
    value: string;
}

/** Patient has focused and then departed the AmountInput text box */
export interface AmountBlurAction {
    type: "amountblur";
}

/** Action pertaining to amount being paid */
export type AmountAction = Readonly<AmountInputAction | AmountBlurAction>;

/** Use logic for entry of payment amount, with validation. Read-only when partial payments are not allowed. */
export function useAmount(
    fullBalance: StrictlyDollars,
    isPayNow: boolean,
    isIntegrated: boolean,
    isEditable: boolean,
    paymentProcessor: PaymentProcessor,
) {
    return useReducer(
        reducer,
        Object.freeze({
            fullBalance,
            isPayNow,
            isIntegrated,
            isEditable,
            paymentProcessor,
            validatedAmount: fullBalance,
            stringAmount: getStringAmount(fullBalance),
            error: null,
        }),
    );
}

function getStringAmount(fullBalance: StrictlyDollars) {
    if (!fullBalance) {
        return "";
    }

    return unformattedStringFromBasisPoints(basisPointsFromDollars(fullBalance));
}

// Note: explicit return type ensures we do not forget a case
function reducer(prior: AmountState, action: AmountAction): AmountState {
    if (prior.fullBalance <= 0 && !prior.isPayNow) {
        console.warn("can't interact with amount when nothing due");
        return prior;
    }

    switch (action.type) {
        case "amountinput":
            return prior.isEditable ? reduceAmountInput(prior, action.value) : prior;
        case "amountblur":
            return reduceAmountBlur(prior);
    }
}

function reduceAmountInput(prior: AmountState, amount: string) {
    const [dollars, error] = validate(amount, false, prior);
    // invariant: validated amount is updated
    return { ...prior, stringAmount: amount, validatedAmount: dollars, error };
}

function reduceAmountBlur(prior: AmountState) {
    if (!!prior.error || prior.validatedAmount !== null) {
        return prior;
    }

    // amount can never change here, only error status
    const [, error] = validate(prior.stringAmount, true, prior);
    return { ...prior, error };
}

/**
 * Check that the number entered is a valid payment amount.
 * If so, return the dollar amount and a null error message.
 * If not, return a null dollar amount and the error message.
 * If entered amount has been adjusted down to full amount, return the full amount and a warning message.
 */
function validate(value: string, blurred: boolean, prior: AmountState) {
    const { fullBalance, isPayNow, isIntegrated, paymentProcessor } = prior;

    {
        const error = getError(value, blurred);
        // if error is a string or null, bail out
        if (typeof error !== "undefined") return [null, error] as const;
        // if error is undefined, continue on
    }

    {
        const amount = parseFloat(value);

        if (isNaN(amount)) {
            // this should never happen, but if it does, it's better to set validatedAmount to null rather than NaN
            console.warn("useAmount value parsed to NaN", value);
            return [null, null];
        }

        if (amount < 0.01) return [null, "Please enter a positive amount"] as const;
        if (amount < 0.5 && paymentProcessor === "Stripe")
            return [null, "Please enter an amount greater than $0.50"] as const;
        if (amount > 100000) return [null, "Please enter an amount less than $100,000"] as const;
        if (amount > fullBalance) {
            // warning-only case; amount still present
            // if not billing admin, cap amount at fullBalance
            return [
                isPayNow ? (amount as StrictlyDollars) : fullBalance,
                getExceededMessage(fullBalance, isIntegrated),
            ] as const;
        }

        return [amount as StrictlyDollars, null] as const; // all good
    }
}

function getExceededMessage(fullBalance: StrictlyDollars, isIntegrated: boolean) {
    return isIntegrated
        ? "Estimated Patient Portion exceeded."
        : `The full balance is ${stringFromDollars(fullBalance)}`;
}

/**
 * Check for empty or invalid input.
 * - When input is erroneous, and we should display an error, return a string conveying said error.
 * - When input is empty, but we haven't blurred yet, return null.
 * - When the input is free of errors, return undefined.
 */
function getError(value: string, blurred: boolean) {
    if (/^[.0+-]*$/.test(value || "")) {
        // show "Please enter an amount" error only if field was visited and blurred
        return blurred ? "Please enter an amount" : null;
    }

    if (!/^[+-]?\d*([.]\d{0,2})?$/.test(value)) {
        return "Please use dollars and whole cents";
    }
}
