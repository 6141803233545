import { AuthContextProvider } from "context/AuthContext";
import { ErrorBoundary } from "@vyne-shared/components/ErrorBoundary";
import { Layout, Typography, theme } from "antd";
import { PageLoading } from "components/Navigation/PageLoading";
import { PaymentPage } from "components/PaymentPage";
import { useCredential } from "utils/useCredential";
import { useLogin } from "api/clients/useLogin";
import { useSentryUser } from "utils/initSentry";

const { Header, Content } = Layout;
const { Title } = Typography;
const { useToken } = theme;

/** Main component for Patient Portal. Provides login using query string. */
export const App = () => {
    const { token } = useToken();

    const credential = useCredential();
    const { data, isPending } = useLogin(credential);
    useSentryUser(data);

    return (
        <Layout>
            <Header style={{ alignItems: "center", backgroundColor: token.colorPrimary }}>
                <Title style={{ color: "white" }}>Statement</Title>
            </Header>
            <Content>
                {isPending || !data ? (
                    <PageLoading />
                ) : (
                    <AuthContextProvider value={data}>
                        <ErrorBoundary resettable>
                            <PaymentPage token={credential?.token} />
                        </ErrorBoundary>
                    </AuthContextProvider>
                )}
            </Content>
        </Layout>
    );
};
