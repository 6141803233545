import "@fontsource/roboto";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "@vyne-shared/components/ErrorBoundary";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { VyneANTDProvider } from "@vyne-shared/components/vyneTheme/VyneThemeProvider";
import { createRoot } from "react-dom/client";
import { initSentry } from "utils/initSentry";
import configuration from "@vyne-shared/utils/config";

if (configuration.environment.isDevelopment()) {
    void import("@axe-core/react").then((axe) => axe.default(React, ReactDOM, 1000));
}

// note that we no longer connect react-query to Sentry for logging
// per react-query docs, "Logging only had an effect in development mode, where passing a custom logger is not necessary"
// see https://tanstack.com/query/v5/docs/react/guides/migrating-to-v5#the-deprecated-custom-logger-has-been-removed
const queryClient = new QueryClient();

// Initialize Sentry early
initSentry();

// Application entry point
const container = document.getElementById("root"); // defined in index.html
const root = createRoot(container!);
root.render(
    <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
            <VyneANTDProvider>
                <BrowserRouter>
                    <App />
                    <ReactQueryDevtools initialIsOpen={false} />
                </BrowserRouter>
            </VyneANTDProvider>
        </QueryClientProvider>
    </ErrorBoundary>,
);
