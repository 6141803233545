import "../PaymentPage/paymentPage.scss";
import { Flex, Spin, Typography } from "antd";
import { NarrowPageContentContainer } from "@vyne-shared/components/vyneNavigation/PageContentContainers";
import { SummaryData } from "components/PaymentPage/SummaryData";

const { Title } = Typography;

export const PageLoading = () => (
    <NarrowPageContentContainer className="payment-page navigation-loading">
        <Flex vertical={true} gap={8} className="payment-page-prompt">
            <Title level={2}>Welcome!</Title>
            <Title level={3}>Please give us one moment...</Title>
        </Flex>
        <Flex justify="center" gap="large" flex-wrap="wrap" className="payment-section">
            <SummaryData />
            <Spin spinning={true} style={{ margin: "auto" }} />
        </Flex>
    </NarrowPageContentContainer>
);
