import { submitCore } from "./submitCore";
import { useEffect } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { usePayment } from "components/Payment/usePaymentContext";
import { useSaleIntent } from "api/clients/useSaleIntent";
import type { StripeEvent, StripeState, StripeStateCommon, StripeSubmittingState } from "./stripeFlow.props";

export function useSubmission(anyState: StripeState, dispatch: React.Dispatch<StripeEvent>) {
    const stripe = useStripe();
    const elements = useElements();

    const submittingState = anyState.type === "submitting" ? anyState : null;
    const canSubmit = !!(stripe && elements);
    useNotReadyWarning(submittingState, canSubmit);

    // useSaleIntent provides mutator to upsert intent
    const { mutateAsync } = useSaleIntent();
    const { prepared, isPayNow, token } = usePayment();
    const { defaultBalance } = prepared;

    useEffect(() => {
        if (submittingState && canSubmit) {
            assertOnce(submittingState.idempotencyPart); // we will not double-submit, even if React goes haywire
            void submitCore(
                stripe,
                elements,
                mutateAsync,
                submittingState,
                dispatch,
                !!isPayNow,
                defaultBalance,
                token,
            );
        }
    }, [submittingState, canSubmit, stripe, elements, mutateAsync, dispatch, isPayNow, defaultBalance, token]);
}

function useNotReadyWarning(submittingState: (StripeSubmittingState & StripeStateCommon) | null, canSubmit: boolean) {
    const wantSubmitButCant = submittingState && !canSubmit;
    useEffect(() => {
        if (wantSubmitButCant) {
            console.warn(
                `not ready for presented submission ${submittingState.idempotencyPart}. will try again when and if elements become ready.`,
            );
        }
    }, [wantSubmitButCant, submittingState]);
}

const idempotencyPartsSeen = new Set<string>();

function assertOnce(idempotencyPart: string) {
    // error case never actually happens
    if (idempotencyPartsSeen.has(idempotencyPart))
        throw new Error(
            "submission effect attempted to submit more than once in response to a single state transition",
        );

    idempotencyPartsSeen.add(idempotencyPart);
}
