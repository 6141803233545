import { type UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "..";

export interface DeleteCardProps {
    /** The id of the patient to delete a card from. */
    patientId: number;

    /** The id of the card to be deleted. */
    cardId: number;
}

type Options = UseMutationOptions<void, unknown, DeleteCardProps>;

/**
 * Client hook to delete a card from a patient.
 * @param options Options for the client call; provides callbacks for when the API call succeeds or fails.
 * @returns React Query mutation properties.
 */
export function useDeleteCard(options?: Pick<Options, "onSuccess" | "onError">) {
    const queryClient = useQueryClient();

    /** Provide built-in onSuccess behavior, then call provided callback if available. */
    const onSuccessWrapper = (_: unknown, variables: DeleteCardProps, context: unknown) => {
        // Invalidate affected queries
        void queryClient.invalidateQueries({
            queryKey: ["getPatientCards", variables.patientId],
        });

        // Use callback if provided
        if (options?.onSuccess) {
            return options.onSuccess(undefined, variables, context);
        }
    };

    return useMutation({
        ...options,
        mutationKey: ["deleteCard"],
        mutationFn: async ({ patientId, cardId }: DeleteCardProps) => await api.patient.deleteCard(patientId, cardId),
        onSuccess: onSuccessWrapper,
    });
}
