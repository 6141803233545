import { api } from "..";
import { useQuery } from "@tanstack/react-query";

/**
 * Client hook to get cards on file for a patient.
 * @param patientId Id of the patient responsible for payment.
 * @returns React Query properties.
 */
export function useGetPatientCards(patientId: number) {
    return useQuery({
        queryKey: ["getPatientCards", patientId],
        queryFn: async (context) => {
            const patientCardData = await api.patient.getCards(patientId, context.signal);

            // show expired cards last
            const sortedCards = patientCardData.sort((a, b) => {
                if (a.isExpired && !b.isExpired) {
                    return 1;
                } else {
                    return -1;
                }
            });

            return sortedCards;
        },
    });
}
