import "./AmountInput.scss";
import { Form, Input } from "antd";
import { currencyInputProps } from "./CurrencyInput";
import { usePayment } from "../../usePaymentContext";
import type { AmountState } from "./useAmount";

/** Amount Input field shown when patient has elected to make a partial payment */
export const AmountInput = () => {
    const { amountState, dispatchAmount, isPayNow, settings } = usePayment();
    const [validateStatus, help] = validate(amountState);

    // Stripe will not process transactions less than 50 cents
    const min = settings.paymentProcessor === "Stripe" ? 0.5 : 0.01;

    // assist with validation by trying to prevent patients from exceeding the full balance
    const max = isPayNow ? 100000 : amountState.fullBalance;

    return (
        <Form.Item validateStatus={validateStatus} help={help} required>
            <Input
                {...currencyInputProps}
                id="enter-amount"
                min={min}
                max={max}
                required
                value={amountState.stringAmount}
                onChange={(e) => dispatchAmount({ type: "amountinput", value: e.target.value })}
                // certain validation (empty input) runs only on blur
                onBlur={() => dispatchAmount({ type: "amountblur" })}
            />
        </Form.Item>
    );
};

function validate(amountState: AmountState) {
    if (amountState.error) {
        const warning = (amountState.validatedAmount ?? 0) > 0;
        return [warning ? "warning" : "error", amountState.error] as const;
    } else {
        return ["", undefined] as const; // all is good.
    }
}
