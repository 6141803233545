import "./currencyInput.scss";
import { preventInputMouseWheel } from "utils/events";
import type { GetProps, Input } from "antd";

type InputProps = GetProps<typeof Input>;

/** Default Input component props for handling currency. */
export const currencyInputProps = {
    type: "number",
    addonBefore: "$",
    placeholder: "0.00",
    step: 0.01,
    min: 0,
    max: 100000,
    className: "currency-input",
    onWheel: preventInputMouseWheel,
} satisfies InputProps;
