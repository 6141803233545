import { api } from "..";
import { useMutation } from "@tanstack/react-query";

export type TerminalCancelMutator = (x: string) => void;

/** For card-present scenarios, provides a mutator to reset readers waiting for a Patient to present their payment method. */
export function useTerminalCancelAction() {
    const { mutate, isPending, isSuccess } = useMutation({
        mutationFn: async (terminalId: string) => {
            return await api.sales.terminalCancelAction(terminalId);
        },
        retry: 2,
    });

    // has the mutation started or succeeded?
    // caller treats these the same, showing spinner indicating cancel-in-progress, as successful cancellation is detected
    // by intent polling (our custom requires_payment_method_on_terminal state transitions to requires_payment_method)
    const isRan = isPending || isSuccess;
    const mutator: TerminalCancelMutator = mutate;
    return { mutate: mutator, isRan } as const;
}
