import { Button, Result } from "antd";
import { HourglassOutlined } from "@ant-design/icons";
import { useTerminalCancelAction } from "api/clients/useTerminalCancelAction";
import type { StripeIntentPollingState, StripeStateCommon } from "./stripeFlow.props";

export interface RequiresPaymentMethodOnTerminalProps {
    state: StripeIntentPollingState & StripeStateCommon;
}

/** "Waiting for Patient" hourglass result, used when a billing admin submits a sale in card-present mode,
 * and we're waiting for the Patient to present a physical payment method to the terminal. */
export const RequiresPaymentMethodOnTerminal = ({ state }: RequiresPaymentMethodOnTerminalProps) => {
    const { mutate, isRan } = useTerminalCancelAction();
    const { terminalId } = state;

    const onCancel = () => {
        if (!terminalId) return;
        mutate(terminalId);
    };

    return (
        <Result
            icon={<HourglassOutlined />}
            title="Waiting for Patient"
            subTitle="Waiting for Patient to present payment method"
            extra={[
                <Button key="terminalCancelAction" onClick={onCancel} loading={isRan}>
                    Cancel
                </Button>,
            ]}
        />
    );
};
