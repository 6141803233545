import { api } from "..";
import { useQuery } from "@tanstack/react-query";

/**
 * Query for the status of a Stripe Payment Intent.
 * @param enabled Whether we are ready to poll for status.
 * @param intentId The ID of the Payment Intent to query for.
 * @param patientId The ID of the Patient/Responsible Party who is making a payment.
 * @returns React query properties including query results.
 */
export function useSaleIntentQuery(enabled: boolean, intentId: string, patientId: number, idempotencyPart: string) {
    const { data, isPending } = useQuery({
        // including idempotencyPart in cache key prevents showing irrelevant stale data from previous submission
        queryKey: ["saleIntent", patientId, intentId, idempotencyPart],
        queryFn: async (context) => await api.saleIntents.getSaleIntent(intentId, patientId, context.signal),
        enabled,
        throwOnError: true,
        refetchInterval: 2000,
        staleTime: 1800,
    });

    return { data, isPending } as const;
}
