// start with backend regex /^[^\s@][^@]*[@][^@]*[^\s@]$/ (which doesn't allow leading/trailing spaces),
// then, to allow leading/trailing spaces (because of frontend email trimming), remove instances of \s
// this isn't the shortest way to write the regex, but it helps keep sync with the backend
const validEmailRegex = new RegExp(/^[^@][^@]*[@][^@]*[^@]$/);

/**
 * Validate an email address at least as strictly as Opera DDS does to ensure we do not get immediate rejections.
 * @param email The email address to validate.
 * @returns True if sufficiently valid to proceed, false otherwise.
 */
export function validateEmail(email?: unknown) {
    if (typeof email === "string" && email.length > 0) {
        // only return true if there is only 1 '@' character and it is neither the first nor the last character
        return validEmailRegex.test(email);
    } else {
        return false;
    }
}
