import { Elements } from "@stripe/react-stripe-js";
import { type StrictlyBasisPoints, basisPointsFromDollars } from "utils/currency";
import { StripeFlow } from "./StripeFlow";
import { theme } from "antd";
import { usePayment } from "../../usePaymentContext";
import { useStripeFlow } from "./StripeFlow/useStripeFlow";
import { useStripeSettings } from "./useStripeSettings";

const { useToken } = theme;

/** Top-level component for making a payment using Stripe. */
export const PayStripe = () => {
    const { amountState } = usePayment();
    const { stripePromise, stripeConnectedAccountId } = useStripeSettings();
    const [state, dispatch] = useStripeFlow();
    const { token } = useToken();

    return (
        <Elements
            stripe={stripePromise}
            options={{
                mode: "payment",
                amount: adjust(basisPointsFromDollars(amountState.validatedAmount ?? 0)),
                currency: "usd",
                // Note that "card" includes Apple Pay and Google Pay, which can be disabled using the `wallets` option
                // on PaymentElement.
                paymentMethodTypes: ["card"],
                setupFutureUsage: state.saveCard ? "off_session" : null,
                onBehalfOf: stripeConnectedAccountId,
                appearance: {
                    variables: {
                        borderRadius: `${token.borderRadius}px`,
                        colorPrimary: token.colorPrimary,
                        fontSizeBase: `${token.fontSize}px`,
                    },
                },
            }}
        >
            <StripeFlow state={state} dispatch={dispatch} />
        </Elements>
    );
};

function adjust(amount: StrictlyBasisPoints) {
    // adjust missing amount (ex. partial payment selected, amount not yet entered) to lowest valid Stripe payment amount (in USD),
    // to stop Elements from throwing exceptions in some cases. Elements does seem to allow switching from a valid balance
    // to an undefined balance, but let's not rely on that.
    // note that based on our implementation, it is impossible for this adjusted amount to actually become attached
    // to a payment intent.
    return Math.max(amount || 0, 50);
}
