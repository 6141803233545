import { pfwconfig } from "./usePayFieldsWorkaround.config";
import { useEffect, useRef, useState } from "react";
import type { PayFieldsState } from "./payFieldsFlow.state";

/**
 * Use PayFields workaround for situations where iframed fields remain 1 pixel high and unusable.
 * @param state payment workflow state, so that we disable the workaround when the form isn't active
 * @returns a string to be used as PayFieldsForm's key, so that it re-mounts when we detect a broken state.
 */
export function usePayFieldsWorkaround(state: PayFieldsState) {
    pfwconfig.freeze();

    const hidden = useDocumentHidden();
    return useRemounterCore(state.type === "initial" && !hidden);
}

/** Is page hidden (as per visibility API)? Listens for changes using document's visibilitychange event. */
function useDocumentHidden() {
    const [hidden, setHidden] = useState(pfwconfig.isHidden());

    useEffect(() => {
        const fn = () => setHidden(pfwconfig.isHidden());

        pfwconfig.onVisibilityChange(fn);

        return () => pfwconfig.unVisibilityChange(fn);
    }, []);

    return hidden;
}

/**
 * Every 100ms, check if PayFields are in a known broken state. If they remain in such a broken state for too long,
 * trigger PayFieldsForm to re-mount by returning a new key for it.
 * @param enabled Used to disable the remounter core when the page is hidden.
 * @returns a string to be used as PayFieldsForm's key.
 */
function useRemounterCore(enabled: boolean) {
    const badCountRef = useRef(0);
    const goodCountRef = useRef(0);

    // negative means form might still be initializing and we'll apply PayFields workaround if needed
    // positive means enough time has passed that we no longer consider the form to be initializing
    const [formKeyId, setFormKeyId] = useState(-1);

    // reset counts every time we change the form key and remount
    useEffect(() => {
        badCountRef.current = 0;
        goodCountRef.current = 0;
    }, [formKeyId]);

    useEffect(() => {
        const timer =
            enabled && formKeyId < 0
                ? setInterval(() => tick(setFormKeyId, badCountRef, goodCountRef), pfwconfig.getTimings().interval)
                : null;

        if (timer) {
            return () => clearInterval(timer);
        }
    }, [formKeyId, enabled]);

    // return always-positive value for use as key
    return pos(formKeyId).toString();
}

/** Perform a check for broken PayFields and update state appropriately. */
function tick(
    setFormKeyId: React.Dispatch<React.SetStateAction<number>>,
    badCountRef: React.MutableRefObject<number>,
    goodCountRef: React.MutableRefObject<number>,
) {
    if (isPayFieldsBroken()) {
        tickBroken(setFormKeyId, badCountRef, goodCountRef);
    } else {
        tickGood(setFormKeyId, goodCountRef, badCountRef);
    }
}

/** Determine if PayFields are in a known broken state (1px high) by checking the Card Number field's height. */
function isPayFieldsBroken() {
    const el = pfwconfig.querySelector("div#card-number iframe");
    if (!el) {
        return false;
    }

    return el.offsetHeight === 1;
}

/** Record the sample result when PayFields are broken. */
function tickBroken(
    setFormKeyId: React.Dispatch<React.SetStateAction<number>>,
    badCountRef: React.MutableRefObject<number>,
    goodCountRef: React.MutableRefObject<number>,
) {
    badCountRef.current += 1;
    goodCountRef.current = 0;
    if (badCountRef.current === pfwconfig.getTimings().badLimit) {
        console.warn("engaging workaround...");
        setFormKeyId((x) => neg(x) - 1);
    }
}

/** Record the sample result when PayFields are working. */
function tickGood(
    setFormKeyId: React.Dispatch<React.SetStateAction<number>>,
    badCountRef: React.MutableRefObject<number>,
    goodCountRef: React.MutableRefObject<number>,
) {
    goodCountRef.current += 1;
    badCountRef.current = 0;
    if (goodCountRef.current >= pfwconfig.getTimings().goodLimit) {
        // form is no longer considered initializing
        setFormKeyId((x) => pos(x));
    }
}

/** @returns the absolute value of a non-zero number. */
function pos(x: number) {
    const abs = Math.abs(x);
    if (abs * -1 === abs) {
        throw new Error("usePayFieldsWorkaround formKeyId identifier must never be zero");
    }
    return abs;
}

/** @returns a negative number with the same distance from zero as the supplied non-zero number. */
const neg = (x: number) => Math.abs(x) * -1;
