import { useEffect } from "react";
import { useSaleIntentQuery } from "api/clients/useSaleIntentQuery";
import type { StripeEvent, StripeState } from "./stripeFlow.props";

/**
 * Perform polling of Payment Intent status to find the result of making a payment.
 * @param state Current Stripe state.
 * @param dispatch Dispatcher to update Stripe state.
 */
export function useSaleIntentPolling(state: StripeState, dispatch: React.Dispatch<StripeEvent>) {
    // Poll as long as we're still in the intent-polling state
    const shouldPoll = state.type === "intent-polling";

    const { data } = useSaleIntentQuery(shouldPoll, state.intentId, state.patientId, state.idempotencyPart);

    useEffect(() => {
        if (data && shouldPoll) {
            dispatch({ type: "ingest-intent", intent: data });
        }
    }, [dispatch, data, shouldPoll]);
}
