import { getCookie, setCookie } from "typescript-cookie";
import { isEqual } from "radashi";

const sessionStorageKey = "vyne-credentials";
const cookieStorageKey = "vyneCredentials";

// Mutable state to store credentials
// We use a file-scoped variable because this is global application state; everything should get the same credentials
// no matter where in the system it is. useRef would likely create separate state for each place the hook is called.
let credentialRef: object | undefined = undefined;

/**
 * Obtain current login credentials. Will continue to provide credentials for the lifetime of the page even if the URL
 * or query string changes.
 * @param initial Initial credentials, constructed from some outside input. Expected to become undefined over time as
 * the user navigates through the system.
 * @returns Credential information, updated if we see something new, or retrieved from storage if necessary.
 */
export function getCredential<T extends object>(initial: T | undefined) {
    // remember the most recent credential with non-empty token
    if (initial && !isEqual(initial, credentialRef)) {
        // only store if something has changed--this includes initialization
        persist(initial);
        credentialRef = initial;
    } else if (!credentialRef) {
        // try loading from session if we don't have anything passed or saved, cascade to cookie if necessary
        credentialRef = sessionGet<T>() ?? cookieGet<T>();

        // if we find something, make sure both places are up-to-date
        if (credentialRef) {
            persist(credentialRef);
        }
    }

    return credentialRef ? (credentialRef as T) : undefined;
}

/** UNIT TESTING ONLY - used to simulate a fresh load of the system */
export function reset() {
    credentialRef = undefined;
}

function persist(credential: object) {
    cookieSet(credential);
    sessionSet(credential);
}

function sessionGet<T>() {
    try {
        const json = sessionStorage.getItem(sessionStorageKey);
        return json ? (JSON.parse(json) as T) : undefined;
    } catch (sessionGetException) {
        // this exception unlikely except in old browsers
        console.warn("Reading credential from session storage failed", sessionGetException);
        return undefined;
    }
}

function sessionSet(credential: object) {
    try {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(credential));
    } catch (sessionSetException) {
        // note session storage is not guaranteed to be available
        console.warn("Could not write credential to session storage", sessionSetException);
    }
}

function cookieGet<T>() {
    try {
        const json = getCookie(cookieStorageKey);
        return json ? (JSON.parse(json) as T) : undefined;
    } catch (cookieGetException) {
        // note cookies are not guaranteed to be enabled
        console.warn("Reading credential from cookies failed", cookieGetException);
        return undefined;
    }
}

function cookieSet(credential: object) {
    try {
        setCookie(cookieStorageKey, JSON.stringify(credential));
    } catch (cookieSetException) {
        // note cookies are not guaranteed to be enabled
        console.warn("Could not write credential to cookie", cookieSetException);
    }
}
