import { useReducer } from "react";
import type { GlobalPaymentsCardForm, GlobalPaymentsFieldTestEventMap } from "./payFields.types";

type FieldTestName = keyof GlobalPaymentsFieldTestEventMap;
type FieldTests = Readonly<Record<FieldTestName, boolean>>;

/** "Field Tests" offered by GPI PayFields to check the validity of each iframed field */
const initialFieldTests: FieldTests = Object.freeze({
    "card-number-test": false,
    "card-expiration-test": false,
    "card-cvv-test": false,
});

// NOTE: This shouldn't be necessary, but TypeScript won't recognize that initialFieldTests only contains the properties
// defined in the type, so we have to assert it. (see https://github.com/microsoft/TypeScript/issues/32811)
function assertFieldTestName(fieldTestName: string): asserts fieldTestName is FieldTestName {
    if (!(fieldTestName in initialFieldTests)) throw new Error("incorrect field test name");
}

/** Action indicating that a field test has been performed (user has edited one of the iframed PayFields) */
export interface FieldTestAction {
    test: FieldTestName;
    valid: boolean;
}

/** Use "Field Tests" offered by GPI PayFields to check the validity of each iframed field */
export function useFieldTests() {
    const [state, dispatch] = useReducer(reducer, initialFieldTests);
    return [allTestsPassed(state), dispatch] as const;
}

const reducer = (prior: FieldTests, action: FieldTestAction) =>
    Object.freeze({ ...prior, [action.test]: action.valid });

const allTestsPassed = (state: FieldTests) => Object.entries(state).every((entry) => entry[1]);

/** Listen to field test events on a GPI PayFields cardForm */
export function attachFieldTests(cardForm: GlobalPaymentsCardForm, dispatch: React.Dispatch<FieldTestAction>) {
    for (const fieldTestName in initialFieldTests) {
        assertFieldTestName(fieldTestName);
        cardForm.on(fieldTestName, (resp) => dispatch({ test: fieldTestName, valid: resp.valid }));
    }
}
