import { Decimal } from "decimal.js";

/** Represents a currency amount as decimal dollars (e.g. $10.32). */
export type Dollars = number;

/**
 * Represents a currency amount as decimal dollars (e.g. $10.32).
 * Branded to help avoid accidentally passing a cents-representing number where a dollar-representing number is expected.
 */
export type StrictlyDollars = Dollars & { __brand: "StrictlyDollars" };

/** Represents a currency amount as integer basis points, i.e. cents. */
export type BasisPoints = number;

/**
 * Represents a currency amount as integer basis points, i.e. cents.
 * Branded to help avoid accidentally passing a dollar-representing number where a cents-representing number is expected.
 */
export type StrictlyBasisPoints = BasisPoints & { __brand: "StrictlyBasisPoints" };

/** Helpful typed constant. */
export const strictlyZero = 0 as StrictlyBasisPoints;

/**
 * Convert a currency amount in Basis Points to decimal dollars.
 * @param amount An amount in basis points (cents).
 * @returns An amount in decimal dollars.
 */
export function dollarsFromBasisPoints(amount: BasisPoints) {
    // Check if amount is actually a whole number first. This very likely indicates a code error that needs to be fixed.
    if (amount % 1 !== 0) {
        throw new Error(`Amount '${amount}' is not in Basis Points.`);
    }

    const basisPoints = new Decimal(amount);
    return basisPoints.dividedBy(100).toDecimalPlaces(2).toNumber() as StrictlyDollars;
}

/**
 * Convert a currency amount in decimal dollars to Basis Points.
 * @param amount An amount in decimal dollars, or a string representing decimal dollars.
 * @returns An amount in basis points (cents).
 */
export function basisPointsFromDollars(amount: Dollars | string) {
    const dollars = new Decimal(amount);
    // fractional cents are not supported in the first place, so just truncate if the user tries to slip them in
    return dollars.times(100).truncated().toNumber() as StrictlyBasisPoints;
}

const currencyFormatOptions: Intl.NumberFormatOptions = {
    currency: "USD",
    currencyDisplay: "narrowSymbol",
    style: "currency",
};

const currencyFormatOptionsShowPositiveSymbol: Intl.NumberFormatOptions = {
    ...currencyFormatOptions,
    signDisplay: "exceptZero",
};

// Use built-in formatting for currency values.
// See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
const currencyFormat = Intl.NumberFormat(navigator.language, currencyFormatOptions);

// Use built-in formatting for currency values, showing plus symbol for positive values.
// See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
const currencyFormatShowPositiveSymbol = Intl.NumberFormat(navigator.language, currencyFormatOptionsShowPositiveSymbol);

/**
 * Convert a currency amount in decimal dollars to a string for display to the user.
 * The formatted result is prefixed with a currency symbol ($), and has commas at thousands places.
 * @param amount An amount in decimal dollars.
 * @returns A formatted string for displaying the amount.
 */
export function stringFromDollars(amount: Dollars) {
    return currencyFormat.format(amount);
}

/**
 * Convert a currency amount in decimal dollars to a string for display to the user.
 * Emits a plus symbol for numbers greater than zero.
 * @param amount An amount in decimal dollars.
 * @returns A formatted string for displaying the amount.
 */
export function stringFromDollarsShowPositiveSymbol(amount: Dollars) {
    return currencyFormatShowPositiveSymbol.format(amount);
}

/**
 * Given basis points, get dollar string prefixed with currency symbol ($), and with commas at thousands places.
 * @param points An amount in basis points (cents).
 * @returns A formatted string for displaying the amount, or "$0" if the input was undefined.
 */
export const stringFromBasisPoints = (points?: StrictlyBasisPoints) =>
    typeof points === "number" ? stringFromDollars(dollarsFromBasisPoints(points)) : "$0";

/**
 * Given basis points, get dollar string without currency symbol ($), and without commas at thousands places.
 * @param points An amount in basis points (cents).
 * @returns A formatted string for displaying the amount, or "0.00" if the input was undefined.
 */
export function unformattedStringFromBasisPoints(points?: StrictlyBasisPoints) {
    const dollars = dollarsFromBasisPoints(points ?? 0);
    return dollars.toFixed(2);
}

/**
 * Given basis points or dollars, invert the value (negative becomes positive, and vice versa, with absolute value
 * remaining the same).
 */
export function invert<TAmount extends StrictlyBasisPoints | StrictlyDollars>(x: TAmount) {
    if (typeof x !== "number") throw new Error("Can't invert non-numbers");
    return (x * -1) as TAmount;
}

/** Given two basis point or dollar values (must be of same type), provide their sum. */
export function add<TAmount extends StrictlyBasisPoints | StrictlyDollars>(x: TAmount, y: TAmount) {
    if (typeof x !== "number" || typeof y !== "number") throw new Error("Can't add non-numbers");
    return (x + y) as TAmount;
}

/** Given two basis point or dollar values (must be of same type), provide their difference. */
export function subtract<TAmount extends StrictlyBasisPoints | StrictlyDollars>(x: TAmount, y: TAmount) {
    if (typeof x !== "number" || typeof y !== "number") throw new Error("Can't subtract non-numbers");
    return (x - y) as TAmount;
}

/** Returns the largest of the numbers given as input parameters. */
export function max<TAmount extends StrictlyBasisPoints | StrictlyDollars>(x: TAmount, y: TAmount | 0) {
    if (typeof x !== "number" || typeof y !== "number") throw new Error("Can't compare non-numbers");
    return Math.max(x, y) as TAmount;
}
