import "./receiptFlow.scss";
import { Alert, Button, Flex, Typography } from "antd";
import { ReceiptForm } from "./ReceiptForm";
import { VyneAlertError } from "components/vyneResults/VyneResultError";
import { isAxiosApiProblemWithDetail } from "api/errors";
import { useReceipt } from "api/clients/useReceipt";
import type { PostPaymentFlowProps } from "..";

const { Title } = Typography;

export interface ReceiptFlowProps extends Pick<PostPaymentFlowProps, "defaultReceiptContact"> {
    patientId: number;
}

export const ReceiptFlow = ({ defaultReceiptContact, patientId }: ReceiptFlowProps) => {
    const { mutate, isPending, isSuccess, error, isError, reset } = useReceipt();

    if (isSuccess) {
        return <Alert message="Successfully Sent Receipt!" type="success" showIcon />;
    }

    if (isError) {
        return <FailedReceiptResponse error={error} retry={reset} />;
    }

    const receiptSentPrompt = defaultReceiptContact?.email
        ? "Want to send an additional receipt?"
        : "Want to send a receipt?";

    return (
        <Flex vertical={true} justify="center" align="center" gap={8}>
            <Title level={4} className="receipt-flow-title">
                {receiptSentPrompt}
            </Title>
            <ReceiptForm patientId={patientId} mutate={mutate} isPending={isPending} defaultEmail={""} />
        </Flex>
    );
};

const FailedReceiptResponse = ({ error, retry }: { error: Error; retry: () => void }) => (
    <VyneAlertError
        type="error"
        aria-label="Failed to Send Receipt"
        message="Failed to Send Receipt"
        description={isAxiosApiProblemWithDetail(error) ? error.response.data.detail : null}
        showIcon
        action={
            <Button size="small" onClick={retry} aria-label="Retry" danger>
                Retry
            </Button>
        }
    />
);
