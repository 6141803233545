import { api as baseApi, client as sharedClient } from "@vyne-shared/api";
import type { ProviderRolodexResponse } from "api/responses";

/** The single shared axios instance used for making API calls. */
export const client = sharedClient;

/** The Payments API wrapper. */
export const api = {
    ...baseApi,
    provider: {
        /** Get basic information about logged in user's provider. */
        rolodex: async (signal?: AbortSignal) => {
            const response = await client.get<ProviderRolodexResponse>("provider/rolodex", { signal });
            return response.data;
        },
    },
};
