import { ErrorMessages } from "@vyne-shared/components/ErrorBoundary";
import { type QueryFunctionContext, keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "api";
import { isResponseUnauthorized } from "@vyne-shared/api/errors";
import { loginPatient } from "api/login";
import { useEffect } from "react";
import type { PatientPortalCredentials } from "utils/useCredential";

type QueryKey = readonly ["login", PatientPortalCredentials | undefined];

/** Get fetch options, including 12 hour staleTime (soft-refetch) */
const fetchOptions = {
    // some say keep this higher than staleTime; but might not matter if our useQuery stays mounted forever
    gcTime: Infinity,
    // 12 hours - must be less than PatientTokenDuration, or we will send expired tokens and requests will just fail!
    // there is no axios-auth-refresh mechanism here like in the main UI to refresh on a 401
    staleTime: 12 * 60 * 60 * 1000,
    retry,
} as const;

/** Client hook to log in to Patient Portal. Also applies axios default headers for API authentication. */
export function useLogin(credential?: PatientPortalCredentials) {
    const result = useQuery({
        ...fetchOptions,
        queryKey: ["login", credential] satisfies QueryKey,
        queryFn: performLogin,
        placeholderData: keepPreviousData,
        throwOnError: true,
    });

    useApiRequestAuth(credential);
    return result;
}

export function retry(failureCount: number, exception: Error) {
    if (isResponseUnauthorized(exception) || ErrorMessages.TokenMissing === exception.message) {
        return false;
    } else {
        return failureCount <= 3; // otherwise retry some amount of times, based on failureCount
    }
}

/**
 * Install axios request interceptor that re-fetches JWT (authToken) if stale and adds it to headers before every
 * request.
 * @param credential Currently known patient portal credentials, whatever they are.
 */
function useApiRequestAuth(credential?: PatientPortalCredentials) {
    const queryClient = useQueryClient();

    useEffect(() => {
        if (credential) {
            const interceptor = client.interceptors.request.use(async (/* AxiosRequestConfig */ config) => {
                // soft-refetch (no actual fetch unless stale, but does fetch and block correctly! also uses the latest
                // result instantly if we re-login without having to re-run the effect, which is nice)
                // NOTE: if we just pass in the login response instead, it seems like it can result in one or more
                // requests making it through before we apply the interceptor, which means they fail due to not having
                // a token. This approach is a little clunkier but does not have that problem. It may be worth
                // investigating further to see if we can make that approach work in case this ever gives us trouble.
                const { authToken } = await queryClient.fetchQuery({
                    queryKey: ["login", credential] as const,
                    queryFn: performLogin,
                });
                // augment request being made with header
                config.headers.Authorization = "Bearer " + authToken;

                return config;
            });
            return () => {
                client.interceptors.request.eject(interceptor); // effect cleanup (uninstall interceptor)
            };
        }
    }, [queryClient, credential]);
}

/** Perform Login by calling Payments Patient Login API. In react-query terms, this is our QueryFunction. */
async function performLogin({ queryKey, signal }: QueryFunctionContext<QueryKey>) {
    const [, credential] = queryKey;

    if (credential?.token) {
        return await loginPatient(credential, signal);
    } else {
        throw new Error(ErrorMessages.TokenMissing); // login token required
    }
}
