// prevent scrolling on input wheel events
export function preventInputMouseWheel(e: React.WheelEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;

    // if the input is focused, blur it to prevent scrolling
    if (document.activeElement === target) {
        // blur element
        target.blur();
    }
}
