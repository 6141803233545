import axios from "axios";
import configuration from "utils/config";
import type {
    GetPatientCardsResponse,
    PatientPrepareSaleResult,
    PatientSaleIntentQueryResult,
    PatientSaleIntentResult,
    PatientSaleResponse,
} from "./responses";
import type { PatientReceiptRequest, PatientSaleIntentRequest, PatientSaleRequest } from "./requests";

/** The single shared axios instance used for making API calls. */
export const client = axios.create({
    baseURL: configuration.apiUrl,
    headers: {
        "Vyne-User-Agent": configuration.pkg,
    },
});

/** The Payments API wrapper. */
export const api = {
    sales: {
        /**
         * Obtain a Reference ID token, which could be used to uniquely identify an imminent provider-entered credit
         * card sale. Also returns important balance information.
         * @param patientId The Payments ID of the Patient/Responsible Party to prepare a Sale for.
         * @returns Token and flag indicating if the token was newly-generated or if an existing Reference ID was used.
         */
        prepareSale: async (patientId: number, signal?: AbortSignal): Promise<PatientPrepareSaleResult> => {
            const response = await client.post<PatientPrepareSaleResult>(`sales/prepare/${patientId}`, { signal });
            return { ...response.data, created: response.status === 201 };
        },

        /**
         * Process tokenized payment for a P/RP.
         * @param request Tokenized information
         */
        performSale: async (request: PatientSaleRequest) =>
            (await client.post<PatientSaleResponse>("sales/perform", request)).data,

        /**
         * For card-present scenarios, resets reader that's waiting for Patient to present a payment method.
         * @param terminalId Identifies payment terminal (reader) to reset.
         */
        terminalCancelAction: async (terminalId: string) => {
            await client.post(`sales/terminal/${terminalId}/cancel`);
        },

        /**
         * Send receipt associated with a successful Stripe Payment Intent.
         * @param request Request information required to send the receipt.
         */
        receipt: async (request: PatientReceiptRequest) => {
            await client.put(`sales/receipt?patientId=${request.patientId}`, {
                email: request.email,
            });
        },
    },
    saleIntents: {
        /**
         * Create a new Stripe Payment Intent.
         * @param request Request information required to create a Payment Intent.
         * @returns The response identifying the new Payment Intent.
         */
        createSaleIntent: async (request: PatientSaleIntentRequest) =>
            (await client.post<PatientSaleIntentResult>("sales/intent", request)).data,

        /**
         * Get the status of a Stripe Payment Intent.
         * @param intentId The ID of the Payment Intent to query for.
         * @param patientId The ID of the Patient/Responsible Party a payment is being made for.
         * @param signal Optional signal to cancel the request.
         * @returns Status information.
         */
        getSaleIntent: async (intentId: string, patientId: number, signal?: AbortSignal) => {
            const response = await client.get<PatientSaleIntentQueryResult>(
                `sales/intent/${intentId}?patientId=${patientId}`,
                { signal },
            );
            return response.data;
        },
    },
    patient: {
        /**
         * Delete a card from a patient
         * @param cardId The ID of the card to delete
         */
        deleteCard: async (patientId: number, cardId: number) => {
            await client.delete(`patient/${patientId}/card/${cardId}`);
        },

        /**
         * Get list of patient's cards on file.
         * @param patientId The ID of the Patient/Responsible Party a payment is being made for.
         * @param signal Optional abort signal to cancel the request.
         * @returns List of a patient's cards on file.
         */
        getCards: async (patientId: number, signal?: AbortSignal) =>
            (await client.get<GetPatientCardsResponse>(`patient/${patientId}/cards`, { signal })).data,
    },
};
