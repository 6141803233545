import { useEffect } from "react";
import type { PayFieldsEvent } from "./payFieldsFlow.events";
import type { PayFieldsState } from "./payFieldsFlow.state";
import type { SaleMutator } from "api/clients/useSale";

export function useSubmission(state: PayFieldsState, dispatch: React.Dispatch<PayFieldsEvent>, mutate: SaleMutator) {
    const shouldSubmit = state.type === "tokenized";

    useEffect(
        () => {
            if (shouldSubmit) {
                mutate(state, {
                    onSuccess: (data) => dispatch({ type: "api-success", data }),
                    onError: (error) => dispatch({ type: "api-error", error }),
                });
            }
        },
        // We only submit when the state changes, not if React ever goes haywire
        // TODO: this should not be an effect, it should be an event handler
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [shouldSubmit],
    );
}
