import { type StrictlyDollars, basisPointsFromDollars } from "utils/currency";
import { api } from "..";
import { useMutation } from "@tanstack/react-query";
import { useRef } from "react";
import type { PatientSaleResponse } from "api/responses";
import type { PayFieldsInitiateSaleData } from "components/Payment/ProcessingPaymentFlow/GPI/PayFieldsFlow/payFieldsFlow.state";

/**
 * Facts about a credit card sale that will be captured and provided to the Sale API, in addition to inputs provided by
 * the PayFields flow.
 */
export interface SaleFacts {
    patientId: number;
    amount: StrictlyDollars | null;
    referenceIdToken?: string;
}

/** Mutator function that posts a sale by calling the Vyne Patient Payments Sale API */
export type SaleMutator = (
    x: PayFieldsInitiateSaleData,
    options?: {
        onSuccess: (data: PatientSaleResponse) => void;
        onError: (error: Error) => void;
    },
) => void;

/**
 * Establish a mutation used by the PayFields flow to call the Vyne Patient Payments Sale API.
 *
 * Rather than coupling the PayFields flow to all of the Sale API inputs, captures the inputs provided within the
 * saleFacts argument. The remaining Sale API inputs are provided by the PayFields flow when calling the mutator
 * function returned by this hook.
 */
export function useSale(saleFacts: SaleFacts) {
    const mutatorRef = useRef(saleFacts);
    mutatorRef.current = saleFacts;

    const { mutate, data, isSuccess } = useMutation({
        mutationFn: async (submissionData: PayFieldsInitiateSaleData) => {
            const { patientId, referenceIdToken, amount } = mutatorRef.current;
            if (!referenceIdToken) throw new Error("referenceIdToken required");
            if (typeof amount !== "number") throw new Error("amount required");

            // note in primary UI code, we invalidate queries patientActivity and patientActivity upon settled
            return await api.sales.performSale({
                ...getPatientSaleCard(submissionData),
                patientId,
                referenceIdToken,
                amount: submissionData.newSale ? basisPointsFromDollars(amount) : undefined,
            });
        },
        throwOnError: false, // onError handler will dispatch api-error
    });

    const mutator: SaleMutator = mutate;
    return { mutate: mutator, data, isSuccess } as const;
}

const Saved: unique symbol = Symbol("Saved");

interface Card {
    [Saved]: false;
    temporary_token: string;
    billingZip: string;
    saveCard: boolean;
}

interface SavedCard {
    [Saved]: true;
    cardOnFileId: number;
}

/** Translates PayFields state union to Sale API input */
function getPatientSaleCard(submissionData: PayFieldsInitiateSaleData) {
    const union = getCardUnion(submissionData);
    if (!union) return;

    return {
        card: !union[Saved] ? clean(union) : undefined,
        savedCard: union[Saved] ? clean(union) : undefined,
    };
}

/**
 * Translates PayFields state union (Card OR Saved Card) into a new union that's closer to what the Sale API wants as
 * input.
 */
function getCardUnion(submissionData: PayFieldsInitiateSaleData) {
    if (!submissionData.newSale) {
        return;
    } else if ("temporary_token" in submissionData) {
        return {
            [Saved]: false,
            temporary_token: submissionData.temporary_token,
            billingZip: submissionData.data.billingZip,
            saveCard: submissionData.saveCard,
        } satisfies Card;
    } else if (submissionData.cardOnFileId) {
        return { [Saved]: true, cardOnFileId: submissionData.cardOnFileId } satisfies SavedCard;
    } else {
        throw new Error("Not enough information to perform a Sale.");
    }
}

/** Delete temporary [Saved] property, freeze, and return the same object reference that was provided */
function clean<TCard extends { [Saved]?: boolean }>(x: TCard) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- it's not dynamic, it's a constant Symbol
    delete x[Saved];
    return Object.freeze(x);
}
