import { ConfigurationCommon } from "@vyne-shared/utils/config";

/** Configuration provided to the system at runtime. */
export class ConfigurationPatientPortal extends ConfigurationCommon {
    constructor() {
        const rawConfig = window.__runtime_configuration;

        if (rawConfig.pkg !== "patient-portal") {
            throw new Error("incorrect package for configuration instance");
        }

        super();
    }
}

/** The shared system configuration data. */
const configuration = new ConfigurationPatientPortal();

export default configuration;
