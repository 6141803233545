import "./vyneResultError.scss";
import { Alert, type GetProps, Result, Space } from "antd";

type ResultProps = GetProps<typeof Result>;
type AlertProps = GetProps<typeof Alert>;

const SubTitle = ({ children }: React.PropsWithChildren) => (
    <Space direction="vertical">
        {children}
        <span>Contact support if this error persists.</span>
    </Space>
);

export const VyneResultError = ({ subTitle, ...props }: ResultProps) => (
    <Result subTitle={<SubTitle>{subTitle}</SubTitle>} {...props} />
);

export const VyneAlertError = ({ description, className, ...props }: AlertProps) => (
    <Alert
        className={`vyne-alert-error ${className ?? ""}`}
        description={<SubTitle>{description}</SubTitle>}
        {...props}
    />
);
