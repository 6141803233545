import { assertGlobalPayments } from "./payFields.types";

// eslint-disable-next-line no-var -- ESLint doesn't understand TypeScript
declare var GlobalPayments: object;

/** Obtain typed form of `GlobalPayments` global variable. */
export function getGlobalPayments() {
    assertGlobalPayments(GlobalPayments);
    return GlobalPayments;
}
