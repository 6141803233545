import "./payment.scss";
import { PaymentContextProvider } from "./PaymentContext";
import { ProcessingPaymentFlow } from "./ProcessingPaymentFlow";
import { Result } from "antd";
import { useStripeInitialContext } from "./ProcessingPaymentFlow/Stripe/useStripeInitialContext";
import type { PatientPrepareSaleResult, ProviderSettings } from "api/responses";

/** Common properties required for making payments. */
export interface PaymentProps {
    /** The ID of the P/RP we are making a payment for. */
    patientId: number;

    // Note: prepared sale is passed in because patient portal needs the information for its own UI; otherwise we could
    // fetch it here. Because it's a POST, we do not want it in two places and accidentally prepare two different sales
    // simultaneously. If patient portal got the statement date and balance some other way, we could consolidate the
    // prepare sale here.
    // Note: the prepared reference ID is tied to quasi-idempotent behavior in GPI APIs. When resettable errors make it
    // to the error boundary and the user choses to try again, another sale is prepared, which is intentional.
    /**
     * The Sale record prepared for this impending payment. Used for both payment processors, but less extensively by
     * Stripe than by GPI.
     */
    prepared: PatientPrepareSaleResult;

    /** Settings required to configure payments for the intended Provider. */
    settings: ProviderSettings;

    /** Flag indicating if we are in the Pay Now dialog, as opposed to Patient Portal. */
    isPayNow?: boolean;

    /** Long-lived patient access token in Patient Portal. */
    token?: string;
}

/**
 * Shared Payment component that allows a user to make a payment.
 *
 * Used in both Patient Portal and Pay Now. Supports both GPI and Stripe as payment processors.
 */
export const Payment = (props: PaymentProps) => {
    const { prepared } = props;

    // If we've been redirected by Stripe, we have to do some work even if there's no apparent balance.
    // pay-now never shows "all caught up", and allows further payments to be made.
    const allCaughtUp = !useDidRedirect() && prepared.created && prepared.defaultBalance <= 0 && !props.isPayNow;

    return allCaughtUp ? (
        <AllCaughtUp />
    ) : (
        <PaymentContextProvider {...props}>
            <ProcessingPaymentFlow />
        </PaymentContextProvider>
    );
};

/** Result shown when we notice there's nothing owed at the start of a session. */
const AllCaughtUp = () => (
    <Result status="success" title="All Caught Up!" subTitle="You have no outstanding balances." />
);

/** Determine whether we've been redirected back to this page by Stripe. */
function useDidRedirect() {
    // check if there's a payment intent id in the query string. applicable to Stripe-based Patient-facing portal only.
    const { intentId } = useStripeInitialContext();
    return !!intentId;
}
