import { Button, Result } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { usePayment } from "components/Payment/usePaymentContext";
import type { PayFieldsErrorState } from "./payFieldsFlow.state";
import type { PayFieldsEvent } from "./payFieldsFlow.events";

export interface ErrorResultProps {
    state: PayFieldsErrorState;
    dispatch: React.Dispatch<PayFieldsEvent>;
}

/**
 * Result shown after a payment fails.
 *
 * When dataForReset is available (which means only for failures during tokenization, not the Sale API), allow the user
 * to return to the PayFields form (with any credit card details intact) to edit details and resubmit.
 *
 * When dataForReset is not available, throw the error, to be caught by our ErrorBoundary.
 */
export const ErrorResult = ({ state, dispatch }: ErrorResultProps) => {
    const { isPayNow } = usePayment();

    if (!state.dataForReset) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error -- these are all throwable Error objects
        throw state.cause;
    }

    const title = `Please check ${isPayNow ? "patient's" : "your"} card details`;

    // for feature parity with prior state where Pay Now was its own codebase, show GPI's action.error.message as
    // error subtitle (for Billing Administrators only)
    // reduceTokenError puts action.error.message in state.content
    const subtitle = isPayNow ? state.content : undefined;

    return (
        <Result
            status="error"
            icon={<CloseCircleOutlined />}
            title={title}
            subTitle={subtitle}
            extra={[
                <Button type="primary" key="reset" onClick={() => dispatch({ type: "reset" })}>
                    Review Payment
                </Button>,
            ]}
        />
    );
};
