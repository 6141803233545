import "./vyneModal.scss";
import { type GetProps, Modal, theme } from "antd";
import { VyneANTDThemeProvider } from "components/vyneTheme/VyneThemeProvider";

const { useToken } = theme;

type ModalProps = GetProps<typeof Modal>;
export type VyneModalProps = ModalProps;

export const VyneModal = ({ children, style, ...props }: React.PropsWithChildren<ModalProps>) => {
    const { token } = useToken();
    const modalProps: ModalProps = { ...props };

    // Force CSS
    modalProps.className = `vyne-modal ${props.className ?? ""}`;

    // Force Render of Content Container with Config Provider for CSS
    // This Is In Case It Renders Outside of Our Default Provider
    modalProps.modalRender = (children: React.ReactNode) => {
        return <VyneANTDThemeProvider>{children}</VyneANTDThemeProvider>;
    };

    // CSS Variables Exposing Some Token Colors
    // Type Forced Cause CSS Properties acts weird with var definitions
    const cssVars: React.CSSProperties = {
        "--vyne-modal-divider-border-color": token.colorBorderSecondary,
    } as React.CSSProperties;

    // Force Container Of Modal to be body unless set by user
    modalProps.getContainer = modalProps.getContainer ?? document.body;

    return (
        <Modal style={{ ...style, ...cssVars }} {...modalProps}>
            {children}
        </Modal>
    );
};
