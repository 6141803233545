import { useEffect } from "react";
import { usePaymentStatus } from "components/usePaymentStatusContext";
import type { PaymentStatus } from "components/PaymentStatusContext";
import type { StripeState } from "./stripeFlow.props";

const statusMap: Record<StripeState["type"], PaymentStatus> = Object.freeze({
    initial: "data-entry",
    submitting: "submitting",
    confirmed: "submitting",
    "intent-polling": "submitting",
    ok: "success",
    error: "error",
});

/** Sync the status of the Stripe operation to the larger payment status. */
export function useStatusSync({ type }: StripeState) {
    const { setStatus } = usePaymentStatus();

    useEffect(() => {
        setStatus(statusMap[type]);
    }, [setStatus, type]);

    // On unmount (happens due to error boundary) trigger return to error state
    useEffect(() => {
        return () => setStatus("error");
    }, [setStatus]);
}
