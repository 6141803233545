import { PayFieldsFlow } from "./PayFieldsFlow";
import { usePayFieldsFlow } from "./PayFieldsFlow/payFieldsFlow.reducers";
import { usePayment } from "../../usePaymentContext";
import { useSale } from "api/clients/useSale";

/** Top-level component for making a payment using GPI. */
export const PayGPI = () => {
    const { prepared, patientId, amountState } = usePayment();

    const [state, dispatch] = usePayFieldsFlow();

    const { mutate, data } = useSale({
        patientId,
        amount: amountState.validatedAmount,
        referenceIdToken: prepared.referenceIdToken,
    });

    return <PayFieldsFlow state={state} dispatch={dispatch} saleData={data} mutate={mutate} />;
};
