import { Button, Result } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Component } from "react";
import { componentDidCatch } from "./componentDidCatch";
import {
    isAxiosApiProblemWithDetail,
    isLoginAttempt,
    isPaymentDeclined,
    isPaymentFailed,
    isResponseUnauthorized,
} from "api/errors";
import type { AxiosError } from "axios";
import type { PatientSaleResponse } from "api/responses";
import type { ResultStatusType } from "antd/lib/result";

/** Properties for the ErrorBoundary component. */
export interface ErrorBoundaryProps extends React.PropsWithChildren {
    /** Allow the error to be cleared to send the user back to the UI as-is. */
    resettable?: boolean;

    /** If true, include additional detail as part of the Pay Now UI. */
    payNow?: boolean;

    /** To close the modal via the Close button in the Take a Payment Flow. */
    hideModal?: () => void;

    /** Callback to handle an error that was caught by the error boundary. */
    onError?: (error: Error) => void;
}

interface ErrorBoundaryState {
    status: ResultStatusType | null;
    title: string;
    subTitle?: string;
    subTitlePayNow?: string;
    resettable?: boolean;
}

/** Predefined error messages that will result in special error boundary handling for display to the user. */
export const ErrorMessages = Object.freeze({
    TokenMissing: "Token Missing",
    DestMissing: "Destination Missing",
    UnableToRefresh: "Unable to refresh authentication token.",
});

/** Error messages that indicate an authorization issue. */
const UnauthorizedErrors: string[] = [ErrorMessages.TokenMissing, ErrorMessages.DestMissing];

const PayNow403 = "Please try again, or contact support for assistance with Pay Now.";

/** Error boundary that will catch certain errors and display them to the user in a legible way. */
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = Object.freeze({ status: null, title: "", subTitle: "" });
    }

    /**
     * Derive state for this component from an unhandled error that was thrown by a child component.
     * @see {@link https://react.dev/reference/react/Component#static-getderivedstatefromerror}
     * @param error The error thrown by the child component. This could be anything.
     * @returns New state for the {@link ErrorBoundary} component.
     */
    static getDerivedStateFromError(error: unknown) {
        return (
            ErrorBoundary.getDerivedStateFromLoginError(error) ??
            ErrorBoundary.getDerivedStateFromSessionError(error) ??
            ErrorBoundary.getDerivedStateFromPaymentError(error) ?? { status: "500", title: "Something went wrong." }
        );
    }

    static getDerivedStateFromLoginError(error: unknown): ErrorBoundaryState | undefined {
        if (isResponseUnauthorized(error)) {
            // user hit something like "?token=abc" and the token was rejected with a 401
            return {
                status: "403",
                title: "Link Not Authorized",
                subTitle: "Please contact your provider for an updated link.",
                subTitlePayNow: PayNow403,
            };
        } else if (isLoginAttempt(error)) {
            // user hit something like "?token=abc" and the token didn't work (but we didn't get a conclusive 401)
            return {
                status: "403",
                title: "Something went wrong trying to log in.",
                subTitle: "Please try again, or contact your provider for assistance.",
                subTitlePayNow: PayNow403,
            };
        }
    }

    static getDerivedStateFromSessionError(error: unknown): ErrorBoundaryState | undefined {
        if (typeof error === "object" && error && "message" in error && typeof error.message === "string") {
            if (UnauthorizedErrors.includes(error.message)) {
                // this is the case where user hits "/" (patient portal root) URL directly
                return {
                    status: "403",
                    title: "Not Authorized",
                    subTitle: "Please use the link you got from your provider to log in.",
                    subTitlePayNow: PayNow403,
                };
            } else if (error.message === ErrorMessages.UnableToRefresh) {
                return {
                    status: "403",
                    title: "Something went wrong re-establishing your access.",
                    subTitle: "Please use the link you got from your provider to log in again.",
                    subTitlePayNow: PayNow403,
                };
            }
        }
    }

    static getDerivedStateFromPaymentError(error: unknown): ErrorBoundaryState | undefined {
        if (isPaymentFailed(error)) {
            return {
                status: isPaymentDeclined(error) ? "warning" : "500",
                title: isPaymentDeclined(error) ? "Payment Declined" : "Payment Failed",
                subTitle: "Please verify your card details, or contact your provider for assistance.",
                subTitlePayNow: getSubTitlePayNow(error),
                resettable: true,
            };
        } else if (isAxiosApiProblemWithDetail(error)) {
            return {
                status: "error",
                title: "Payment Failed",
                subTitle: "Please verify your card details, or contact your provider for assistance.",
                subTitlePayNow: error.response.data.detail,
                resettable: false,
            };
        }
    }

    // https://eddiewould.com/2021/28/28/handling-rejected-promises-error-boundary-react/
    // Must be an arrow function for proper capturing of "this"
    private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
        this.setState(ErrorBoundary.getDerivedStateFromError(event.reason));
    };

    override componentDidMount() {
        // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
        window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
    }

    override componentWillUnmount() {
        window.removeEventListener("unhandledrejection", this.promiseRejectionHandler);
    }

    override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        componentDidCatch(error, errorInfo);

        if (this.props.onError) {
            this.props.onError(error);
        }
    }

    override render() {
        const { status, title, subTitle, subTitlePayNow } = this.state;
        const { payNow } = this.props;

        if (status) {
            // error case, so error boundary kicks in
            const displaySubTitle = payNow && subTitlePayNow ? subTitlePayNow : subTitle;
            return (
                <Result
                    status="error"
                    icon={<CloseCircleOutlined />}
                    title={title}
                    subTitle={displaySubTitle}
                    extra={this.getExtra()}
                />
            );
        } else {
            // non-error case
            return this.props.children;
        }
    }

    private getExtra() {
        if (this.props.resettable && this.state.resettable) {
            const setState = this.setState.bind(this);

            const displayCloseButton = () => {
                return this.props.payNow ? (
                    <Button type="default" onClick={() => this.props.hideModal?.()}>
                        Close
                    </Button>
                ) : null;
            };

            return [
                displayCloseButton(),
                <Button type="primary" key="reset" onClick={() => setState({ status: null, title: "" })}>
                    Review Statement
                </Button>,
            ];
        }
    }
}

function getSubTitlePayNow(error: AxiosError<PatientSaleResponse>) {
    // for feature parity with prior state where Pay Now was its own code base, show GPI's processorResponse as
    // error subtitle (for Billing Administrators only)
    // reduceApiError sets the axios error in state.cause, then ErrorResult re-throws it using "throw state.cause"
    const defaultSubTitlePayNow = "Please verify the patient's card details.";
    let subTitlePayNow = error.response?.data.processorResponse ?? error.response?.data.status ?? defaultSubTitlePayNow;

    // handle 50X error being passed as subtitle
    if (typeof subTitlePayNow === "number") {
        subTitlePayNow = defaultSubTitlePayNow;
    }

    return subTitlePayNow;
}
