import { useEffect } from "react";
import { usePaymentStatus } from "components/usePaymentStatusContext";
import type { PayFieldsState } from "./payFieldsFlow.state";
import type { PaymentStatus } from "components/PaymentStatusContext";

const statusMap: Record<PayFieldsState["type"], PaymentStatus> = Object.freeze({
    initial: "data-entry",
    tokenizing: "submitting",
    tokenized: "submitting",
    done: "success",
    error: "error",
});

export function useStatusSync({ type }: PayFieldsState) {
    const { setStatus } = usePaymentStatus();

    useEffect(() => {
        setStatus(statusMap[type]);
    }, [setStatus, type]);

    // On unmount (happens due to error boundary) trigger return to error state
    useEffect(() => {
        return () => setStatus("error");
    }, [setStatus]);
}
