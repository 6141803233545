import { loadStripe } from "@stripe/stripe-js/pure";
import { usePayment } from "../../usePaymentContext";
import { useRef, useState } from "react";
import type { ProviderSettings } from "api/responses";

type StripeSettings = Pick<ProviderSettings, "stripeConnectedAccountId" | "stripePublishableKey">;
type RequiredStripeSettings = Required<StripeSettings>;

/**
 * Obtain the Stripe promise required for Stripe elements to function and the Connected Account ID payments will be
 * sent to.
 */
export function useStripeSettings() {
    const { settings } = usePayment();

    useStripeSettingsAssertions(settings);
    const { stripePublishableKey, stripeConnectedAccountId } = settings;

    // Ensure we only load Stripe once by using an initializer function
    const [stripePromise] = useState(() => loadStripe(stripePublishableKey));

    return { stripePromise, stripeConnectedAccountId } as const;
}

function useStripeSettingsAssertions(settings: StripeSettings): asserts settings is RequiredStripeSettings {
    const { stripeConnectedAccountId, stripePublishableKey } = settings;

    if (!stripeConnectedAccountId) {
        throw new Error("Stripe Connected Account ID required to use Stripe");
    }

    if (!stripePublishableKey) {
        throw new Error("Stripe Publishable Key required to use Stripe");
    }

    // ensure that stripeConnectedAccountId does not change in the session
    // we tolerate stripePublishableKey changing, but ignore any changes and retain the old value
    const idRef = useRef(stripeConnectedAccountId);
    if (idRef.current !== stripeConnectedAccountId) {
        throw new Error("Stripe Connected Account ID must not change");
    }
}
