import type { AxiosError } from "axios";
import type { GlobalPaymentsTokenError, GlobalPaymentsTokenSuccessEvent } from "../PayFields/payFields.types";
import type { NonIframedFields } from "./payFieldsFlow.events";
import type { PatientSaleResponse } from "api/responses";
import type { SavedCardState } from "components/Payment/Components/SavedCard/props";

/** Pay fields initial state. Shows the fields, requests credit card details. */
export interface PayFieldsInitialState {
    type: "initial";
    data: NonIframedFields;
}

/** State entered immediately after user clicks Submit ("Pay $X") */
export interface PayFieldsTokenizingState {
    type: "tokenizing";
    data: NonIframedFields;
}

interface RetrySaleData {
    newSale: false;
    saveCard: false;
}

interface EnteredCardSaleData extends GlobalPaymentsTokenSuccessEvent {
    newSale: true;
    cardOnFileId: null;
    data: NonIframedFields;
    saveCard: boolean;
}

interface SavedCardSaleData {
    newSale: true;
    cardOnFileId: number;
    saveCard: false;
}

/**
 * Event data for either style of payment submission (using new card data tokenized via PayFields, or using saved card).
 * In either case, in response to the event, we're going to call our Payments Sale API.
 */
export type PayFieldsInitiateSaleData = EnteredCardSaleData | SavedCardSaleData | RetrySaleData;

/**
 * Pay fields tokenized state. Shows "One Moment" spinner.
 * This is an intermediate state, where tokenization returned, and we're now calling Payments sale API.
 * Note that "tokenized" here can mean PayFields provided a temporary token OR that the user has submitted a payment
 * using a saved card.
 * Consider renaming to PayFieldsSubmittingState or PayFieldsSubmissionInProgressState.
 */
export type PayFieldsTokenizedState = { type: "tokenized" } & PayFieldsInitiateSaleData;

/** Pay fields error state. */
export interface PayFieldsErrorState {
    type: "error";
    content: string;
    dataForReset: NonIframedFields | null;
    cause: AxiosError<PatientSaleResponse> | Error | GlobalPaymentsTokenError;
}

/** Pay fields done state. */
export interface PayFieldsDoneState {
    type: "done";
    content: string;
}

/** Any of the above-defined pay fields states. */
export type PayFieldsStateTypes =
    | PayFieldsInitialState
    | PayFieldsTokenizingState
    | PayFieldsErrorState
    | PayFieldsTokenizedState
    | PayFieldsDoneState;

/** Any of the PayFields states plus required common properties. */
export type PayFieldsState = PayFieldsStateTypes & SavedCardState & { terminalId: null };

/**
 * Get an initial state.
 * In the typical case where we will be posting a new Sale, returns a state with type: "initial".
 * Less typically, if we're recovering a previous Sale, returns a state with type: "tokenized".
 */
export const getInitialState = (created: boolean) => (created ? initialState : retryState);

const initialState: PayFieldsState = {
    type: "initial",
    data: { billingZip: "" },
    cardOnFileId: null,
    terminalId: null,
    saveCard: false,
};

const retryState: PayFieldsState = {
    type: "tokenized",
    newSale: false,
    cardOnFileId: null,
    terminalId: null,
    saveCard: false,
};
