import { type StrictlyDollars, basisPointsFromDollars } from "utils/currency";
import { api } from "..";
import { useMutation } from "@tanstack/react-query";
import type { PatientSaleIntentResult } from "api/responses";

export interface SaleIntentSubmissionData {
    patientId: number;
    amount: StrictlyDollars;
    idempotencyPart: string;
    intentId: string;
    clientSecret: string;
    cardOnFileId: number | null;
    terminalId: string | null;
    saveCard: boolean;
}

export type SaleIntentMutator = (x: SaleIntentSubmissionData) => Promise<PatientSaleIntentResult>;

/**
 * Client hook to create a new Stripe Payment Intent.
 * @returns React Query mutation properties.
 */
export function useSaleIntent() {
    const { mutateAsync } = useMutation({
        mutationFn: async (submissionData: SaleIntentSubmissionData) => {
            const { amount, intentId, clientSecret, cardOnFileId } = submissionData;
            if (typeof amount !== "number") throw new Error("amount required");

            return await api.saleIntents.createSaleIntent({
                ...submissionData,
                amount: basisPointsFromDollars(amount),
                intentId: nullify(intentId),
                clientSecret: nullify(clientSecret),
                cardOnFileId: nullify(cardOnFileId),
            });
        },
        throwOnError: true,
    });

    const mutatorAsync: SaleIntentMutator = mutateAsync;
    return { mutateAsync: mutatorAsync } as const;
}

function nullify<T>(x: T) {
    return x ? x : null;
}
