import { type AxiosError, isAxiosError } from "axios";
import { type PayFieldsState, getInitialState } from "./payFieldsFlow.state";
import { markPaymentFailed } from "api/errors";
import { usePayment } from "components/Payment/usePaymentContext";
import { useReducer } from "react";
import { useStatusSync } from "./useStatusSync";
import type { PatientSaleResponse } from "api/responses";
import type {
    PayFieldsApiErrorEvent,
    PayFieldsApiSuccessEvent,
    PayFieldsEvent,
    PayFieldsPayClickedEvent,
    PayFieldsResetEvent,
    PayFieldsSetFieldsEvent,
    PayFieldsTokenErrorEvent,
    PayFieldsTokenEvent,
} from "./payFieldsFlow.events";
import type {
    SelectSavedEvent,
    SetSaveCardEvent,
    SubmitUsingSavedEvent,
} from "components/Payment/Components/SavedCard/props";

/** Use PayFields flow. Provides state and dispatch, so that the state can be observed as well as modified. */
export function usePayFieldsFlow() {
    const { prepared } = usePayment();

    const [state, dispatch] = useReducer(reduce, getInitialState(prepared.created));

    useStatusSync(state);

    return [state, dispatch] as const;
}

function reduce(prior: PayFieldsState, action: PayFieldsEvent): PayFieldsState {
    switch (action.type) {
        case "set-fields":
            return reduceSetFields(prior, action);

        case "pay-clicked":
            return reducePayClicked(prior, action);

        case "submit-using-saved":
            return reduceSubmitUsingSaved(prior, action);

        case "token-success":
            return reduceTokenSuccess(prior, action);

        case "token-error":
            return reduceTokenError(prior, action);

        case "api-success":
            return reduceApiSuccess(prior, action);

        case "api-error":
            return reduceApiError(prior, action);

        case "reset":
            return reduceReset(prior, action);

        case "select-saved-card":
            return reduceSelectSavedCard(prior, action);

        case "select-terminal":
            throw new Error("not applicable for GPI");

        case "set-save-card":
            return reduceSetSavedCard(prior, action);
    }
}

function reduceSetFields(prior: PayFieldsState, action: PayFieldsSetFieldsEvent) {
    if (prior.type === "initial") {
        return { ...prior, data: { ...prior.data, billingZip: action.data.billingZip } };
    } else {
        return warnNonApplicable(prior, action);
    }
}

function reducePayClicked(prior: PayFieldsState, action: PayFieldsPayClickedEvent) {
    if (prior.type === "initial") {
        return { ...prior, type: "tokenizing" } as const;
    } else {
        return warnNonApplicable(prior, action);
    }
}

function reduceSubmitUsingSaved(prior: PayFieldsState, action: SubmitUsingSavedEvent) {
    if (prior.type === "initial" && prior.cardOnFileId) {
        return {
            ...prior,
            type: "tokenized",
            newSale: true,
            cardOnFileId: prior.cardOnFileId,
            saveCard: false,
        } as const;
    } else {
        return warnNonApplicable(prior, action);
    }
}

function reduceTokenSuccess(prior: PayFieldsState, action: PayFieldsTokenEvent) {
    if (prior.type === "tokenizing") {
        const { temporary_token, card } = action;

        return {
            type: "tokenized",
            data: prior.data,
            temporary_token,
            card,
            saveCard: prior.saveCard,
            cardOnFileId: null,
            newSale: true,
            terminalId: null,
        } as const;
    } else {
        return warnNonApplicable(prior, action);
    }
}

function reduceTokenError(prior: PayFieldsState, action: PayFieldsTokenErrorEvent) {
    // tokenization failed (earlier stage)
    const dataForReset = "data" in prior ? prior.data : null;
    return {
        type: "error",
        content: action.error.message,
        dataForReset,
        cause: action.error,
        cardOnFileId: null,
        saveCard: false,
        terminalId: null,
    } as const;
}

function reduceApiSuccess(prior: PayFieldsState, { data }: PayFieldsApiSuccessEvent) {
    if (prior.type === "tokenizing") {
        throw new Error("can't get api response (error) while payment processor is still tokenizing");
    }

    return {
        type: "done",
        content: data.processorResponse || data.status,
        cardOnFileId: prior.cardOnFileId,
        saveCard: false,
        terminalId: null,
    } as const;
}

function reduceApiError(prior: PayFieldsState, action: PayFieldsApiErrorEvent) {
    if (prior.type === "tokenizing") {
        throw new Error("can't get api response (error) while payment processor is still tokenizing");
    }

    let content: string;
    let cause: AxiosError<PatientSaleResponse> | Error;
    if (isAxiosError<PatientSaleResponse, unknown>(action.error)) {
        // our API failed (later stage)
        const data = action.error.response?.data ?? { processorResponse: "", status: "" };
        content = data.processorResponse || data.status;
        cause = markPaymentFailed(action.error);
    } else {
        content = "Unknown error";
        cause = new Error(content, { cause: action.error });
    }

    return {
        type: "error",
        content,
        dataForReset: null,
        cause,
        cardOnFileId: prior.cardOnFileId,
        saveCard: false,
        terminalId: null,
    } as const;
}

function reduceReset(prior: PayFieldsState, action: PayFieldsResetEvent) {
    if ("dataForReset" in prior && prior.dataForReset) {
        return {
            type: "initial",
            data: prior.dataForReset,
            cardOnFileId: prior.cardOnFileId,
            saveCard: prior.saveCard,
            terminalId: null,
        } as const;
    } else {
        return warnNonApplicable(prior, action);
    }
}

function reduceSelectSavedCard(prior: PayFieldsState, action: SelectSavedEvent) {
    if (prior.type === "initial") {
        return { ...prior, cardOnFileId: action.cardOnFileId };
    } else {
        return warnNonApplicable(prior, action);
    }
}

function reduceSetSavedCard(prior: PayFieldsState, action: SetSaveCardEvent) {
    if (prior.type === "initial") {
        if (prior.cardOnFileId) {
            // can't set save-card when calling for use of card on file
            return prior;
        }

        return { ...prior, saveCard: action.saveCard };
    } else {
        return warnNonApplicable(prior, action);
    }
}

function warnNonApplicable(prior: PayFieldsState, action: PayFieldsEvent) {
    console.warn(`${action.type} non-applicable in ${prior.type}`);
    return prior;
}
