import { type UseMutationOptions, type UseMutationResult, useMutation } from "@tanstack/react-query";
import { api } from "..";
import type { PatientReceiptRequest } from "api/requests";

type SendReceiptOptions = Omit<UseMutationOptions<void, Error, PatientReceiptRequest>, "mutationFn">;

export type PatientReceiptMutator = (x: PatientReceiptRequest) => void;

/** Provide a mutator to send receipt associated with a successful Stripe Payment Intent. */
export function useReceipt(options?: SendReceiptOptions): UseMutationResult<void, Error, PatientReceiptRequest> {
    return useMutation({
        ...options,
        mutationFn: async (request: PatientReceiptRequest) => await api.sales.receipt(request),
    });
}
