import axios from "axios";
import configuration from "utils/config";
import type { PatientLoginResponse } from "./responses";
import type { PatientPortalCredentials } from "utils/useCredential";

/**
 * Log in to Payments as a Patient.
 * @param param0 Patient credentials to use when logging in.
 * @param signal Optional signal that can abort logging in if the user cancels loading.
 * @returns Patient login response.
 */
export async function loginPatient({ channel, token }: PatientPortalCredentials, signal?: AbortSignal) {
    const response = await axios.post<PatientLoginResponse>("login/patient", undefined, {
        baseURL: configuration.apiUrl,
        signal,
        headers: { Authorization: `PatientPortal ${token}`, "Communication-Channel": channel ?? "x" },
    });

    return response.data;
}
