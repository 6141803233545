import { type GetProps, type GetRef, Select } from "antd";
import { useRef } from "react";

type SelectProps<T> = GetProps<typeof Select<T>>;
type SelectRef<T> = GetRef<typeof Select<T>>;

export interface VyneSelectProps<T> extends Omit<SelectProps<T>, "virtual"> {
    "aria-label": string;
}

export function VyneSelect<T>({ ...props }: VyneSelectProps<T>) {
    const selectProps: SelectProps<T> = { ...props };
    const selectRef = useRef<SelectRef<T>>(null);

    selectProps.dropdownStyle = {
        ...(props.dropdownStyle ?? {}),
        minWidth: "fit-content",
    };

    selectProps.style = {
        ...(props.style ?? {}),
        maxWidth: "fit-content",
    };

    const handlePopupContainer: SelectProps<T>["getPopupContainer"] = () => {
        if (selectRef.current?.nativeElement.parentElement) {
            return selectRef.current.nativeElement.parentElement;
        }
        return document.body;
    };

    selectProps.getPopupContainer = props.getPopupContainer ?? handlePopupContainer;

    return <Select virtual={false} ref={selectRef} {...selectProps} />;
}
