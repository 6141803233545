import { useSearchParams } from "react-router-dom";
import type { StrictlyBasisPoints } from "utils/currency";

/** Properties passed in by a Stripe redirect. */
export interface StripeRedirectEntryProps {
    /** The ID of the Payment Intent that Stripe is processing. */
    intentId: string;

    /** The client secret used by Stripe to act on the Payment Intent. */
    clientSecret: string;
}

/** Additional properties passed in by a Stripe redirect that are not used to drive the Stripe Flow state. */
export interface StripeInitialContextProps extends StripeRedirectEntryProps {
    /** Total balance that we were making a payment towards. */
    balance?: StrictlyBasisPoints;
}

/** Check for and return any initial context provided by Stripe via a redirect. */
export function useStripeInitialContext(): StripeInitialContextProps {
    const [search] = useSearchParams();
    const intentId = search.get("payment_intent") ?? "";
    const clientSecret = search.get("payment_intent_client_secret") ?? "";
    const balanceStr = search.get("balance");
    const balance = balanceStr ? (parseInt(balanceStr) as StrictlyBasisPoints) : undefined;
    return { intentId, clientSecret, balance };
}
