import { Flex, Spin } from "antd";
import { FormItemGroup } from "components/vyneForm/items/VyneFormItems";
import { PayButton } from "components/Payment/Components/PayButton";
import { PaymentElement } from "@stripe/react-stripe-js";
import { SharedAmountControls } from "components/Payment/Components/Amount";
import { SharedSavedCardControls } from "components/Payment/Components/SavedCard";
import { type StrictlyDollars, stringFromDollars } from "utils/currency";
import { usePayment } from "components/Payment/usePaymentContext";
import { useState } from "react";
import type { StripeEvent, StripeState } from "./stripeFlow.props";
import type { StripeFlowProps } from ".";

/** The data entry form to prepare a Stripe payment. */
export const DataEntry = ({ state, dispatch }: StripeFlowProps) => {
    const [notInitial, notError] = [state.type !== "initial", state.type !== "error"];

    const spinning = notInitial && notError;
    const display = notError ? "block" : "none";

    return (
        <Spin spinning={spinning} tip="Submitting payment...">
            <FormItemGroup title="Payment Details" style={{ display }}>
                <Flex vertical={true} gap="1rem">
                    <SharedAmountControls />
                    <DataEntryForm state={state} dispatch={dispatch} />
                </Flex>
            </FormItemGroup>
        </Spin>
    );
};

const DataEntryForm = ({ state, dispatch }: StripeFlowProps) => {
    const { amountState } = usePayment();
    const amount = amountState.validatedAmount;

    // tracking whether the Payment Element is ready
    const [isReady, setIsReady] = useState(false);

    const payMode = determinePayMode(amount, state, isReady);
    const options = usePaymentOptions();

    // "something on file" used to mean that we're not asking for credit card details to be entered using form fields,
    // that is, card-on-file has been engaged, or a terminal (reader) has been selected. consider re-naming this concept
    // to something more suitable.
    const somethingOnFileSelected = !!state.cardOnFileId || !!state.terminalId;

    // note when className is not set, Stripe library assigns default class "StripeElement".
    // we hide PaymentElement instead of removing it because it must exist for Stripe to function, even when using a
    // saved card.
    const className = somethingOnFileSelected ? "StripeElement vyne-payment-fields-hidden" : "StripeElement";

    return (
        <>
            <SharedSavedCardControls payMode={payMode} state={state} dispatch={dispatch} />
            <PaymentElement className={className} options={options} onChange={(event) => setIsReady(event.complete)} />
            {somethingOnFileSelected ? null : (
                <PayButton id="stripe-flow-submit" payMode={payMode} onSubmit={() => onSubmit(amount, dispatch)} />
            )}
        </>
    );
};

/** Get the payment button text and enablement */
function determinePayMode(amount: StrictlyDollars | null, state: StripeState, isReady: boolean) {
    const somethingOnFileSelected = !!state.cardOnFileId || !!state.terminalId;

    const enabled = !!amount && state.type === "initial" && (somethingOnFileSelected || isReady);
    const text = enabled ? `Pay ${stringFromDollars(amount)}` : "Pay";

    return { text, enabled };
}

function usePaymentOptions() {
    const { isPayNow } = usePayment();

    // Pay Now doesn't support wallets; they cannot be accessed remotely
    const wallet = isPayNow ? "never" : "auto";

    return { wallets: { applePay: wallet, googlePay: wallet } } as const;
}

function onSubmit(amount: StrictlyDollars | null, dispatch: React.Dispatch<StripeEvent>) {
    if (amount) {
        dispatch({ type: "submit-clicked", amount });
    }
}
