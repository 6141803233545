const visibilityChange = "visibilitychange";
// eslint-disable-next-line @typescript-eslint/no-inferrable-types -- we redefine this for testing
const interval: number = 100;

/** Mockable function wrappers and configuration for usePayFieldsWorkaround */
export const pfwconfig = {
    /** Freeze usePayFieldsWorkaround configuration, making it read-only with runtime enforcement. */
    freeze: () => Object.freeze(pfwconfig),
    /** Is page hidden (as per visibility API)? */
    isHidden: () => !!document.hidden,
    /** Listen to visibilitychange events. */
    onVisibilityChange: (fn: () => void) => document.addEventListener(visibilityChange, fn),
    /** Stop listening to visibilitychange events. */
    unVisibilityChange: (fn: () => void) => document.removeEventListener(visibilityChange, fn),
    /** Search for an HTML element by selector. */
    querySelector: (selector: string) => document.querySelector<HTMLElement>(selector),
    /** Get timing-related configuration. */
    getTimings: () =>
        Object.freeze({
            /** Determines how often we check for a broken state. */
            interval,
            /** Determines the number of intervals after which the workaround will engage and re-mount the form. */
            badLimit: Math.floor(1000 / interval), // 1 second, or 10 intervals (number of intervals must be an integer)
            /** Determines the number of intervals after which the workaround will permanently disengage. */
            goodLimit: Math.floor(25000 / interval), // 25 seconds, or 250 intervals
        }),
} as const;
