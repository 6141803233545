import { type User, init, setUser } from "@sentry/react";
import { sharedSentryConfig } from "@vyne-shared/utils/sentryConfig";
import { useEffect } from "react";
import configuration from "utils/config";
import type { PatientLoginResponse } from "api/responses";

/** Initialize Sentry, and enable Sentry in non-development environments */
export const initSentry = () =>
    init({
        ...sharedSentryConfig(configuration),
        dsn: "https://0964eb396dee4e2d884665bfc0df6638@o119772.ingest.sentry.io/6781432", // payments-patientportal
    });

/** Set sentry user (only re-sets if actually changed) */
export function useSentryUser(result?: PatientLoginResponse) {
    const { id, customerId, customerName, trellisGlobalCustomerId } = buildSentryUser(result);

    useEffect(() => {
        if (id) setUser({ id, customerId, customerName, trellisGlobalCustomerId });
    }, [id, customerId, customerName, trellisGlobalCustomerId]);
}

/**
 * Build a Sentry user object from a login response.
 * @param result The result of logging in.
 * @returns The Sentry user object.
 */
const buildSentryUser = (result?: PatientLoginResponse) =>
    ({
        id: result?.patientId.toString(),
        customerId: result?.customerId,
        customerName: result?.customerName,
        trellisGlobalCustomerId: result?.trellisGlobalCustomerId,
    }) satisfies User;
