import "./thanks.scss";
import { Button, type GetProps, Result } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { ThanksPatientPortalBody } from "./ThanksPatientPortalBody";
import { usePayment } from "components/Payment/usePaymentContext";
import { usePaymentStatus } from "components/usePaymentStatusContext";
import type { DefaultReceiptContact } from "api/responses";
import type { StrictlyBasisPoints } from "utils/currency";

type ResultProps = GetProps<typeof Result>;

/**
 * Properties for "Thanks for your payment!" component.
 * Extends antd ResultProps, so that in special cases, portions of the Result can be overridden.
 * */
export interface ThanksProps extends ResultProps {
    amount?: StrictlyBasisPoints;
    detail?: string;
    defaultReceiptContact?: DefaultReceiptContact;
}

/**
 * Show "Thanks for your payment!" with various facts, using an antd Result.
 * Shown only during the original session in which the payment was made.
 */
export const Thanks = (props: ThanksProps) => {
    const { isPayNow } = usePayment();
    const { hideModal } = usePaymentStatus();

    const { defaultReceiptContact, detail } = props;

    const resultTitle = isPayNow ? "Payment Successful" : "Thanks for your payment!";

    const backButton = isPayNow ? (
        <Button type="primary" onClick={hideModal}>
            Back to Patients
        </Button>
    ) : null;

    return (
        <>
            <Result
                icon={<CheckCircleOutlined />}
                className="vyne-thanks-result"
                title={resultTitle}
                subTitle={BuildReceiptSentPrompt(isPayNow ?? false, defaultReceiptContact, detail)}
                extra={backButton}
                {...props}
            />
            {!isPayNow && <ThanksPatientPortalBody amount={props.amount} />}
        </>
    );
};

const BuildReceiptSentPrompt = (isPayNow: boolean, defaultReceiptContact?: DefaultReceiptContact, detail?: string) => {
    const receiptSentFacts = defaultReceiptContact?.email
        ? {
              sentMessageType: "Receipt",
              sentMessageContactForPractice: defaultReceiptContact.email,
              sentMessageContactForPatient: "your inbox",
          }
        : defaultReceiptContact?.phone
          ? {
                sentMessageType: "Payment notification",
                sentMessageContactForPractice: defaultReceiptContact.phone,
                sentMessageContactForPatient: "your phone",
            }
          : {
                sentMessageType: "Payment notification",
                sentMessageContactForPractice: "patient",
                sentMessageContactForPatient: "patient",
            };

    const receiptSentPrompt = `${receiptSentFacts.sentMessageType} successfully sent to ${isPayNow ? receiptSentFacts.sentMessageContactForPractice : receiptSentFacts.sentMessageContactForPatient}!`;

    return (
        <p>
            {receiptSentPrompt}
            {detail && isPayNow && (
                <>
                    <br />
                    {detail}
                </>
            )}
        </p>
    );
};
