import "./vyneInputRadio.scss";
import { ConfigProvider, Flex, type GetProps, Radio, theme } from "antd";

const { useToken } = theme;

type RadioProps = GetProps<typeof Radio>;

interface VyneInputRadioProps extends RadioProps {
    /* Details to display next to the radio input */
    details?: React.ReactElement;

    /* Whether to remove the details section from the dom */
    removeDetails?: boolean;

    /* Whether to hide the details section visually with css */
    hideDetails?: boolean;

    /* Type of radio input which controls the colors of the radio element */
    type?: "default" | "warning" | "error";
}

export const VyneInputRadio = ({
    details,
    type = "default",
    hideDetails,
    removeDetails,
    ...props
}: VyneInputRadioProps) => {
    const { token } = useToken();
    const radioProps: RadioProps = { ...props };

    // Force CSS
    radioProps.className = `vyne-input-radio-btn ${props.className ?? ""}`;
    const flexClassName = `vyne-input-radio ${hideDetails ? "vyne-input-radio-hide-details" : ""}`;

    const typeColors = {
        default: {
            color: token.colorPrimary,
            bg: token.colorPrimaryBg,
        },
        warning: {
            color: token.colorWarning,
            bg: token.colorWarning,
        },
        error: {
            color: token.colorError,
            bg: token.colorErrorBg,
        },
    };

    return (
        <Flex vertical={true} className={flexClassName} gap="small">
            <ConfigProvider
                theme={{
                    components: {
                        Radio: {
                            wireframe: true,
                            colorPrimary: typeColors[type].color,
                        },
                    },
                }}
            >
                <Radio {...radioProps} />
            </ConfigProvider>
            {details !== undefined && !removeDetails ? <div className="vyne-input-radio-details">{details}</div> : null}
        </Flex>
    );
};
