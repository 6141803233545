import "./antd-override.scss";
import "./vyneThemeBase.scss";
import { App, ConfigProvider } from "antd";
import { VyneTheme } from "./vyneThemeConfig";
import { extend } from "dayjs";
import { useRef } from "react";
import utc from "dayjs/plugin/utc";

// --------------------------------------------------------------------------------------
//
//  Config Setup
//
// --------------------------------------------------------------------------------------

// Set up config for dayJS in ANTD to return UTC
extend(utc);

// --------------------------------------------------------------------------------------
//
//  Props
//
// --------------------------------------------------------------------------------------

interface VyneANTDProviderProps extends React.PropsWithChildren {
    testMode?: boolean;
}

// --------------------------------------------------------------------------------------
//
//  Sub Providers
//
// --------------------------------------------------------------------------------------

export const VyneANTDThemeProvider = ({ testMode = false, children }: VyneANTDProviderProps) => {
    const configRef = useRef<HTMLDivElement>(null);

    return (
        <ConfigProvider
            theme={{ ...VyneTheme, token: { ...VyneTheme.token, motion: !testMode } }}
            getPopupContainer={() => {
                if (configRef.current) {
                    return configRef.current;
                }
                return document.body;
            }}
        >
            <div ref={configRef} className="popup-container">
                {children}
            </div>
        </ConfigProvider>
    );
};

const VyneANTDAppProvider = ({ children }: React.PropsWithChildren) => (
    <App
        message={{
            maxCount: 1,
        }}
        notification={{
            maxCount: 1,
        }}
    >
        {children}
    </App>
);

// --------------------------------------------------------------------------------------
//
//  Providers
//
// --------------------------------------------------------------------------------------

export const VyneANTDProvider = ({ children, testMode = false }: VyneANTDProviderProps) => (
    <VyneANTDThemeProvider testMode={testMode}>
        <VyneANTDAppProvider>{children}</VyneANTDAppProvider>
    </VyneANTDThemeProvider>
);
