import { api } from "api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

/**
 * Client hook to obtain Provider Rolodex information (name, address, etc.).
 * @returns React Query properties.
 */
export function useProviderRolodex() {
    return useQuery({
        queryKey: ["providerRolodex"],
        queryFn: async (context) => await api.provider.rolodex(context.signal),
        throwOnError: true,
        placeholderData: keepPreviousData,
        gcTime: Infinity,
        staleTime: Infinity,
    });
}
