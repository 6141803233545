import { attachFieldTests, useFieldTests } from "./useFieldTests";
import { getGlobalPayments } from "./getGlobalPayments";
import { useEffect, useRef } from "react";
import configuration from "utils/config";
import css2json from "css2json";
import payFieldsStyles from "./payFields.scss?inline";
import type { GlobalPaymentsCardForm } from "./payFields.types";

/** Prefixes for environments that count as production. */
const productionEnvironmentPrefixes = ["stag", "prod"];

/** Use production PayFields when in production, otherwise use test. */
const payFieldsEnvironment = configuration.environment.hasAnyPrefix(productionEnvironmentPrefixes) ? "prod" : "test";

/** Standard configuration for the fields that we use. */
const payFieldsConfig = {
    "card-number": {
        target: "#card-number", // per-field-iframe will be rendered to this target
        placeholder: "•••• •••• •••• ••••",
    },
    "card-expiration": {
        target: "#card-expiration",
        placeholder: "MM / YYYY",
    },
    "card-cvv": {
        target: "#card-cvv",
        placeholder: "•••",
    },
    submit: {
        text: "Pay", // note text may be customized
        target: "#submit",
    },
};

/** Prepare the GPI PayFields form and components. */
export function useGlobalPaymentsForm(prepareForm: (form: GlobalPaymentsCardForm) => void) {
    const [fieldTestsPassed, dispatchFieldTest] = useFieldTests();
    const assertOnceRef = useRef(0);
    const globalPayments = getGlobalPayments();

    useEffect(() => {
        if (++assertOnceRef.current > 1) throw new Error("PayFields form must not reconfigure");

        globalPayments.configure({
            "X-GP-Api-Key": configuration.payFieldsKey,
            "X-GP-Environment": payFieldsEnvironment,
        });

        const cardForm = globalPayments.ui.form({
            fields: payFieldsConfig,
            styles: css2json(payFieldsStyles),
        });

        prepareForm(cardForm);

        attachFieldTests(cardForm, dispatchFieldTest);
    }, [globalPayments, prepareForm, dispatchFieldTest]);

    return fieldTestsPassed;
}
