import type { ConfirmPaymentData, Stripe, StripeElements } from "@stripe/stripe-js";

export interface StripeConfirm {
    elements?: StripeElements;
    clientSecret: string;
    confirmParams: ConfirmPaymentData;
    redirect: "always" | "if_required";
}

/**
 * Adapter to call `confirmPayment` with redirect option specified dynamically.
 * Stripe's argument types do not allow this by default.
 */
export function stripeConfirm(stripe: Stripe, options: StripeConfirm) {
    if (isAlways(options)) {
        return stripe.confirmPayment(options);
    } else if (isIfRequired(options)) {
        return stripe.confirmPayment(options);
    } else {
        throw new Error("incorrect redirect option");
    }
}

function isAlways(x: StripeConfirm): x is StripeConfirm & { redirect: "always" } {
    return x.redirect === "always";
}

function isIfRequired(x: StripeConfirm): x is StripeConfirm & { redirect: "if_required" } {
    return x.redirect === "if_required";
}
