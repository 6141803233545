import { Button } from "antd";
import type { PayButtonMode } from "./payButtonMode";

interface PayButtonProps {
    /** Button ID used for testing so we can tell which button it is. */
    id: string;

    /** Pay Button mode indicating text and enabled state. */
    payMode: PayButtonMode;

    /** Handler to fire when the button is clicked. */
    onSubmit: () => void;
}

/** Standard Pay button with consistent styling. */
export const PayButton = ({ id, payMode, onSubmit }: PayButtonProps) => (
    <Button
        data-testid={id}
        type="primary"
        disabled={!payMode.enabled}
        onClick={onSubmit}
        style={{ display: "block", margin: "1em auto 0 auto" }}
    >
        {payMode.text}
    </Button>
);
