import { createContext, useContext, useMemo } from "react";
import type { PatientLoginResponse } from "api/responses";

/** Auth context provides relevant PatientLoginResponse properties. Notably, authToken is excluded. */
type AuthContextProps = Omit<PatientLoginResponse, "authToken">;

/** Auth context provides relevant PatientLoginResponse properties. Notably, authToken is excluded. */
interface AuthContextProviderProps extends React.PropsWithChildren {
    value?: AuthContextProps;
}

/** Simple auth context that captures login result without tracking login state. */
const AuthContext = createContext<AuthContextProps | undefined>(undefined);

/** Provider for simple auth context that captures login result without tracking login state. */
export const AuthContextProvider = ({ children, value }: AuthContextProviderProps) => {
    const frozenValue = useMemo(() => {
        if (!value) {
            return undefined;
        }

        return Object.freeze({ ...value });
    }, [value]);

    return <AuthContext.Provider value={frozenValue}>{children}</AuthContext.Provider>;
};

/** Provide definitive (non-nullish) Auth Context */
// eslint-disable-next-line react-refresh/only-export-components -- for context only, this is fine
export function useAuth() {
    const auth = useContext(AuthContext);
    if (!auth) throw new Error("auth context not available");
    return auth;
}
