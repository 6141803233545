import { getCredential } from "@vyne-shared/utils/credential";
import { useLocation, useSearchParams } from "react-router-dom";

/** Credentials used to log in to the Payments application from the Patient Portal. */
export interface PatientPortalCredentials {
    /** Long-lived patient access token. */
    token: string;

    /** Channel via which the Patient received the link. */
    channel: string | null;
}

/**
 * Obtain Patient Portal login credentials provided in the initial query string.
 * Will continue to provide credentials for the lifetime of the page even if the URL or query string changes.
 */
export function useCredential() {
    const urlPartsBySlash = useLocation().pathname.split("/");
    // if there are 2 slashes resulting in 3 parts, then url format is /{token}/{channel}
    const isShortUrl = urlPartsBySlash.length === 3;
    const [search] = useSearchParams();
    const token = isShortUrl ? urlPartsBySlash[1]! : search.get("token");
    const channel = isShortUrl ? urlPartsBySlash[2]! : search.get("channel");

    // Ensure initial credentials are either valid or undefined, never a broken or empty object!
    const initial = token ? ({ token, channel } satisfies PatientPortalCredentials) : undefined;

    return getCredential(initial);
}
