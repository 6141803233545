import { type AxiosError, isAxiosError } from "axios";
import type { PatientSaleResponse } from "./responses";

/** Partial definition of {@link https://www.rfc-editor.org/rfc/rfc7807 RFC 7807} problem details structure. */
export interface ApiProblem {
    /** A short, human-readable summary of the problem type. */
    title: string;

    /** The HTTP status code generated by the origin server for this occurrence of the problem. */
    status: number;

    /** A human-readable explanation specific to this occurrence of the problem. */
    detail: string;
}

/** Axios error that (at least roughly) conforms to {@link https://www.rfc-editor.org/rfc/rfc7807 RFC 7807}. */
export type AxiosApiProblem = AxiosError<ApiProblem> & Required<Pick<AxiosError<ApiProblem>, "response">>;

/**
 * Check if the given error is an Axios error that (at least roughly) conforms to
 * {@link https://www.rfc-editor.org/rfc/rfc7807 RFC 7807}.
 * @param error The error message to inspect.
 * @returns True if the error conforms to the requirements, false otherwise.
 */
export function isAxiosApiProblem(error: unknown): error is AxiosApiProblem {
    if (isAxiosError(error)) {
        const data: unknown = error.response?.data;

        return (
            typeof data === "object" &&
            !!data &&
            "title" in data &&
            typeof data.title === "string" &&
            "status" in data &&
            typeof data.status === "number" &&
            "detail" in data &&
            typeof data.detail === "string"
        );
    }

    return false;
}

/**
 * Check if the given error is an Axios error that (at least roughly) conforms to
 * {@link https://www.rfc-editor.org/rfc/rfc7807 RFC 7807}, with indication that the cause is the specified exception
 * type.
 *
 * Refer to ConfigureProblemDetails in Startup.cs for how exceptions are exposed and mapped to statuses.
 *
 * @param error The error message to inspect.
 * @param status The HTTP status that corresponds to the specified exception.
 * @param exception The exception type to look for.
 * @returns True if the error conforms to the requirements, false otherwise.
 */
export function isApiException(error: unknown, status: number, exception: string): error is AxiosApiProblem {
    return isAxiosApiProblem(error) && error.response.status === status && error.response.data.title === exception;
}

/**
 * Check if the given error is an Axios error that (at least roughly) conforms to
 * {@link https://www.rfc-editor.org/rfc/rfc7807 RFC 7807}, includes some sort of detail message, and optionally has a
 * specified HTTP status.
 * @param error The error message to inspect.
 * @param requiredStatus The optional HTTP status code to require.
 * @returns True if the error conforms to the requirements, false otherwise.
 */
export function isAxiosApiProblemWithDetail(error: unknown, requiredStatus?: number): error is AxiosApiProblem {
    return (
        isAxiosApiProblem(error) &&
        error.response.data.detail.length > 0 &&
        (typeof requiredStatus === "undefined" || error.response.status === requiredStatus)
    );
}

/**
 * Get the HTTP status code associated with an error.
 * @param exception Some sort of exception or error that was thrown.
 * @returns The HTTP status code that caused the error, or -1 if unknown.
 */
export function getResponseStatus(exception: unknown) {
    if (isAxiosError(exception)) {
        const status = exception.response?.status;
        if (typeof status === "number") {
            return status;
        }
    }

    return -1;
}

/**
 * Determine if an error represents an HTTP 401 Unauthorized error.
 * @param exception Some sort of exception or error that was thrown.
 * @returns True if the error was caused by an HTTP 401 Unauthorized response, false otherwise.
 */
export function isResponseUnauthorized(exception: unknown) {
    return getResponseStatus(exception) === 401;
}

/**
 * Determine if an error occurred while attempting to log in.
 * @param exception Some sort of exception or error that was thrown.
 * @returns True if the error was caused during a login attempt, false otherwise.
 */
export function isLoginAttempt(exception: unknown) {
    if (isAxiosError(exception)) {
        const url = exception.config?.url ?? "";
        return url.toLowerCase().includes("login/patient");
    }

    return false;
}

const PaymentFailedMarker: unique symbol = Symbol("PaymentFailedMarker");
const PaymentDeclinedMarker: unique symbol = Symbol("PaymentDeclinedMarker");

/** Check whether an error object represents a failed payment. */
export function isPaymentFailed(err: unknown): err is AxiosError<PatientSaleResponse> {
    return isAxiosError(err) && PaymentFailedMarker in err && !!err[PaymentFailedMarker];
}

/**
 * Check whether an error object represents a declined payment (a subset of failed payments).
 * This is currently not very precise. Any axios error during the sale mutation is treated as a decline.
 */
export function isPaymentDeclined(err: unknown): err is AxiosError<PatientSaleResponse> {
    return isAxiosError(err) && PaymentDeclinedMarker in err && !!err[PaymentDeclinedMarker];
}

/**
 * Mark an error object as being associated with a failed payment.
 * Also, if applicable, marks the error object as being associated with a declined payment (see {@link isPaymentDeclined}).
 */
export function markPaymentFailed(err: AxiosError<PatientSaleResponse>) {
    Object.defineProperty(err, PaymentFailedMarker, {
        value: true,
        writable: false,
        configurable: false,
    });
    markPaymentDeclined(err);
    return err;
}

function markPaymentDeclined(err: AxiosError<PatientSaleResponse>) {
    if (err.isAxiosError && typeof err.response?.data.status === "string" && err.response.data.status.length > 0) {
        Object.defineProperty(err, PaymentDeclinedMarker, {
            value: true,
            writable: false,
            configurable: false,
        });
    }
}
