import type { Environment } from "utils/environment";

/** Configuration information required for PayFields to function. */
export interface VynePayFieldsConfiguration {
    /** The environment the system is running in. */
    readonly environment: Environment;

    /** The SDK key for PayFields. */
    readonly payFieldsKey: string;
}

/** Credit card data made available by PayFields. */
export interface GlobalPaymentsCard {
    /** The two-digit month the card expires, expressed as a string (e.g. "04"). */
    expiry_month: string;

    /** The two-digit year the card expires, expressed as a string. */
    expiry_year: string;

    /** The credit card number, but with portions masked off. */
    masked_card_number: string;

    /** The type or brand of card being used (e.g. "Visa"). */
    type: string;
}

/** token-success Event that occurs when a card entered using PayFields has been successfully tokenized. */
export interface GlobalPaymentsTokenSuccessEvent {
    /** The temporary token to use in place of credit card data when initiating a transaction. */
    temporary_token: string;

    /** The card information returned by PayFields. */
    card: GlobalPaymentsCard;
}

/** Error information returned by PayFields. */
export interface GlobalPaymentsTokenError {
    /** Machine-readable code identifying the error. */
    code: string;

    /** Human-readable message describing the error. */
    message: string;
}

/** token-error Event data when a card was unable to be tokenized. */
export interface GlobalPaymentsTokenErrorEvent {
    /** Error information returned by PayFields. */
    error: GlobalPaymentsTokenError;
}

/** "Field Tests" offered by GPI PayFields to check the validity of each iframed field */
export interface GlobalPaymentsFieldTestEvent {
    valid: boolean;
}

/**
 * GPI PayFields events without subtypes mapped to event-specific callback type (related to tokenization).
 *
 * When binding to these events, the second argument to "on" is the callback (see
 * {@link GlobalPaymentsEventSubtypeEventMap} below for 3-argument example with subtype).
 *
 * When binding to these events, the full type of the callback function will be the mapped type, ex.:
 * ```
 * cardForm.on("token-success", function (gpiResponse: GlobalPaymentsTokenSuccessEvent) { })
 * ```
 */
interface GlobalPaymentsTokenEventMap {
    "token-success": (gpiResponse: GlobalPaymentsTokenSuccessEvent) => void;
    "token-error": (gpiResponse: GlobalPaymentsTokenErrorEvent) => void;
}

/**
 * GPI PayFields events without subtypes mapped to event-specific callback type (related to field tests).
 *
 * Comment on {@link GlobalPaymentsTokenEventMap} applies here too.
 */
export interface GlobalPaymentsFieldTestEventMap {
    "card-number-test": (gpiResponse: GlobalPaymentsFieldTestEvent) => void;
    "card-expiration-test": (gpiResponse: GlobalPaymentsFieldTestEvent) => void;
    "card-cvv-test": (gpiResponse: GlobalPaymentsFieldTestEvent) => void;
}

/**
 * GPI PayFields events that have subtypes mapped to their subtype values.
 *
 * Binding example (note second argument to "on" is subtype string, and third argument is callback):
 * ```
 * cardForm.on("submit", "click", function (gpiResponse: GlobalPaymentsSubmitButtonEvent) { })
 * ```
 */
interface GlobalPaymentsSubmitEventMap {
    submit: "click"; // just one subtype: "click". in theory, this could be ex. "click" | "blur"
}

interface TwoParamMap extends GlobalPaymentsTokenEventMap, GlobalPaymentsFieldTestEventMap {}
type ThreeParamMap = GlobalPaymentsSubmitEventMap;

/**
 * GPI PayFields event type maps.
 *
 * For events without subtypes, map to handler type, ex. `(gpiResponse: GlobalPaymentsTokenSuccessEvent) => void`
 *
 * For events with subtypes, map to subtype values, ex. "click"
 */
export interface GlobalPaymentsEventMap extends TwoParamMap, ThreeParamMap {}

/**
 * GPI PayFields events that have subtypes mapped to event payload type.
 *
 * Binding example (note second argument to "on" is subtype string, and third argument is callback):
 * ```
 * cardForm.on("submit", "click", function (gpiResponse: GlobalPaymentsSubmitButtonEvent) { })
 * ```
 */
interface GlobalPaymentsEventSubtypeEventMap {
    submit: Record<string, never>;
}

export type OnArgs<TType extends keyof GlobalPaymentsEventMap> = TType extends keyof TwoParamMap
    ? [type: TType, handler: TwoParamMap[TType]]
    : TType extends keyof ThreeParamMap
      ? [
            type: TType,
            subType: ThreeParamMap[TType],
            handler: (gpiResponse: GlobalPaymentsEventSubtypeEventMap[TType]) => void,
        ]
      : never;

/** PayFields cardForm provides "on" method for binding to events */
export interface GlobalPaymentsCardForm {
    on: <TType extends keyof GlobalPaymentsEventMap>(...args: OnArgs<TType>) => void;
}

/** PayFields configuration */
export interface GlobalPaymentsConfiguration {
    "X-GP-Api-Key": string;
    "X-GP-Environment": string;
}

/** PayFields field configuration */
export type GlobalPaymentsFormField = { target: string; placeholder: string } | { target: string; text: string };

/** PayFields form configuration */
export interface GlobalPaymentsFormConfiguration {
    fields: Record<string, GlobalPaymentsFormField>;
    styles: unknown;
}

/** GlobalPayments "ui" property provides access to form via a method */
export interface GlobalPaymentsUi {
    form: (cfg: GlobalPaymentsFormConfiguration) => GlobalPaymentsCardForm;
}

/** GlobalPayments global object */
export interface GlobalPaymentsGlobal {
    configure: (cfg: GlobalPaymentsConfiguration) => void;
    ui: GlobalPaymentsUi;
}

/** assert that value passed to gpi argument seems to be a valid GlobalPayments global object */
export function assertGlobalPayments(gpi: unknown): asserts gpi is GlobalPaymentsGlobal {
    if (typeof gpi !== "object" || !gpi) throw new Error("PayFields root global not present");
    if (!("configure" in gpi) || typeof gpi.configure !== "function")
        throw new Error("PayFields configure global not present");
    if (!("ui" in gpi) || typeof gpi.ui !== "object" || !gpi.ui) throw new Error("PayFields UI not present");
    if (!("form" in gpi.ui) || typeof gpi.ui.form !== "function")
        throw new Error("PayFields UI form global not present");
}
